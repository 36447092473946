<template>
  <v-snackbar v-model="showSnackbar" :timeout="3000" color="error">
    {{ errorMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
        بستن
      </v-btn>
    </template>
  </v-snackbar>

  <div>
    <q-dialog v-model="isLoading" seamless position="top" style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <q-card>
        <q-card-section class="row items-center">
          <q-spinner color="primary" size="3em" class="q-mr-sm"/>
          <span class="text-primary">در حال بارگذاری، لطفاً منتظر بمانید...</span>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>

  <div>
    <q-dialog v-model="showSuccessMessage" seamless position="top" style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <q-card class="bg-green-4 text-white">
        <q-card-section class="row items-center">
          <q-icon name="check_circle" size="3em" color="white" class="q-mr-sm"/>
          <span>اطلاعات با موفقیت ارسال شد!</span>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>

</template>

<script>

export default {
  name: 'UiUtils',
  data() {
    return {
      errorMessage: '',
      showSnackbar: false,
      isLoading: false,
      showSuccessMessage: false
    };
  },
  methods: {
    showError(message) {
      this.errorMessage = message;
      this.showSnackbar = true;
    },
    showSuccessDialog() {
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 2000);
    },
    showSnackErrorMessage(error) {
      this.showError(error);
    },
    showIsLoading() {
      this.isLoading = true
    },
    dismissIsLoading() {
      this.isLoading = false
    }
  },
};
</script>
