<template>
  <v-locale-provider rtl style="font-family: 'fontBody', sans-serif;">
    <div class="container" style="font-family: 'fontBody', sans-serif;">
      <div class="row" style="padding-left: 15px;padding-right: 15px">
        <div class="col-md-12">
          <div class="stats-container">
            <div class="stat-item">
              <h5>مجموع سفارشات امروز : <span class="stat-value">{{ convertNumberToFarsi(todayOrderCount, false) }}</span></h5>
            </div>
            <div class="stat-item">
              <h5>مجموع ورود امروز : <span class="stat-value">{{ convertNumberToFarsi(todayLoginCount, false) }}</span></h5>
            </div>

          </div>
        </div>
      </div>
      <div class="row" style="font-family: 'fontBody', sans-serif;">
        <div class="col-md-6" style="padding-right: 15px;padding-left: 15px;">
          <div class="chart-container">
            <div class="chart-title-container" style="background-color: #f5f5f5;">
              <h5 class="chart-title" style="color: #454C57">گفتگو(چت)</h5>
            </div>
            <canvas ref="chatChartCanvas"></canvas>
          </div>
        </div>
        <div class="col-md-6" style="padding-right: 15px;padding-left: 15px;">
          <div class="chart-container">
            <div class="chart-title-container" style="background-color: #f5f5f5;">
              <h5 class="chart-title" style="color: #454C57">سفارشات</h5>
            </div>
            <canvas ref="orderChartCanvas"></canvas>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" style="padding-right: 15px;padding-left: 15px;">
          <div class="chart-container">
            <div class="chart-title-container" style="background-color: #f5f5f5;">
              <h5 class="chart-title" style="color: #454C57">امتیاز آهنگ</h5>
            </div>
            <canvas ref="musicActivityChart"></canvas>
          </div>
        </div>
        <div class="col-md-6" style="padding-right: 15px;padding-left: 15px;">
          <div class="chart-container">
            <div class="chart-title-container" style="background-color: #f5f5f5;">
              <h5 class="chart-title" style="color: #454C57">میزهای ورود</h5>
            </div>
            <canvas ref="deskLoginChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div>
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>

<script>
import Chart from 'chart.js/auto';
import ApiService from '../services/ApiService.js';
import UiUtils from '../components/util/UiUtils.vue';
import * as persianHelpers from "persian-helpers";

export default {
  name: 'AdminDashboard',
  components: {
    UiUtils
  },
  data() {
    return {
      todayLoginCount: 0,
      todayOrderCount: 0,
      chatChartData: {},
      chatChart: null,
      orderChart: null,
      musicActivityChart: null,
      deskLoginChart: null,
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    };
  },
  computed: {
    chatChartLabels() {
      return this.chatChartData.chat.map((item) => item.day);
      // return this.chatChartData.chat.map((item) => this.convertNumberToFarsi(item.day, false));
    },
    chatChartValues() {
      return this.chatChartData.chat.map((item) => item.count);
    },

    orderChartLabels() {
      // return this.chatChartData.orderChart.map((item) => this.convertNumberToFarsi(item.day, false));
      return this.chatChartData.orderChart.map((item) => item.day);
    },
    orderChartValues() {
      return this.chatChartData.orderChart.map((item) => item.count);
    },

    musicActivityChartLabels() {
      return this.chatChartData.musicActivityChart.map((item) => item.day);
      // return this.chatChartData.musicActivityChart.map((item) => this.convertNumberToFarsi(item.day, false));
    },
    musicActivityChartValues() {
      return this.chatChartData.musicActivityChart.map((item) => item.count);
    },

    deskLoginChartLabels() {
      return this.chatChartData.deskLoginChart.map((item) => item.day);
      // return this.chatChartData.deskLoginChart.map((item) => this.convertNumberToFarsi(item.day, false));
    },
    deskLoginChartValues() {
      return this.chatChartData.deskLoginChart.map((item) => item.count);
    },
  },
  async mounted() {
    await this.fetchChatData();

  },
  methods: {
    convertNumberToFarsi(price, sep) {
      return persianHelpers.convertDigits(price, {separator: sep});
    },
    async fetchChatData() {

      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/dashboard/getAnalyse", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        console.log(response.chatChart)

        this.createChatChart(response.chatChart);
        this.orderChatChart(response.orderChart);
        this.musicActivityChartMet(response.musicActivityChart);
        this.deskLoginChartMet(response.deskLoginChart);

        this.todayLoginCount = response.deskLoginChart[response.deskLoginChart.length-1].count
        this.todayOrderCount = response.orderChart[response.orderChart.length-1].count

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(error)
      })

    },
    createChatChart(chatChart) {
      this.chatChartData.chat = chatChart;
      const ctx = this.$refs.chatChartCanvas.getContext('2d');
      this.chatChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chatChartLabels,
          datasets: [
            {
              label: 'تعداد چت‌ها',
              data: this.chatChartValues,
              borderColor: '#f1266d',
              fill: false,
              animation: {
                duration: 1500,
                easing: 'easeOutQuart',
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    orderChatChart(chart) {
      this.chatChartData.orderChart = chart;
      const ctx = this.$refs.orderChartCanvas.getContext('2d');
      this.orderChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.orderChartLabels,
          datasets: [
            {
              label: 'سفارشات',
              data: this.orderChartValues,
              borderColor: '#f1266d',
              fill: false,
              animation: {
                duration: 1500,
                easing: 'easeOutQuart',
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    deskLoginChartMet(chart) {
      this.chatChartData.deskLoginChart = chart;
      const ctx = this.$refs.deskLoginChart.getContext('2d');
      this.orderChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.deskLoginChartLabels,
          datasets: [
            {
              label: 'میز ورود',
              data: this.deskLoginChartValues,
              borderColor: '#f1266d',
              fill: false,
              animation: {
                duration: 1500,
                easing: 'easeOutQuart',
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    musicActivityChartMet(chart) {
      this.chatChartData.musicActivityChart = chart;
      const ctx = this.$refs.musicActivityChart.getContext('2d');
      this.orderChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.musicActivityChartLabels,
          datasets: [
            {
              label: 'امتیاز آهنگ',
              data: this.musicActivityChartValues,
              borderColor: '#f1266d',
              fill: false,
              animation: {
                duration: 1500,
                easing: 'easeOutQuart',
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'fontBody';
  src: url('/src/assets/Vazir.ttf') format('opentype');
}

.container {
  margin-top: 2rem;
}

.stats-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.stat-item {
  text-align: center;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: bold;
}

.chart-container {
  background-color: #F5F5F8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.chart-title-container {
  background-color: #8c4b37;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px 8px 0 0;
  margin-bottom: 1rem;
  text-align: center;
}

.chart-title {
  margin: 0;
  font-weight: bold;
}

.body-font {
  font-family: 'fontBody', sans-serif;
}
</style>
