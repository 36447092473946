<template dir="rtl">
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import ApiService from './services/ApiService';

export default {
  name: 'App',
  created() {
    this.getUserPermissions();
  },
  data: () => ({
    config: {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json'
      },
    },
    userPermissions: []
  }),

  methods: {
    getUserPermissions() {
      ApiService.get("/authController/getUserPermissions", this.config)
          .then(response => {
            this.userPermissions = response.data;
            console.log("response userPermissions: ", this.userPermissions)
          })
          .catch(error => {
            console.log(error);
          });
    },

    hasPermission(permissionCode) {
      if (permissionCode.value == null) {
        if (permissionCode.submenu) {
          for (let i = 0; i < permissionCode.submenu.length; i++) {
            const subItem = permissionCode.submenu[i];
            const has = this.userPermissions.map(permission => permission.module).includes(subItem.value);
            if (has) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      } else {
        const has = this.userPermissions.map(permission => permission.module).includes(permissionCode.value);
        return has;
      }
    },
    hasModule(moduleCode) {
      return this.userPermissions.map(permission => permission.module).includes(moduleCode);
    }
  },
}
</script>
