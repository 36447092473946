<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>

                    <q-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewCategoryDialog"
                        style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;"
                    >
                      <v-icon small>mdi-pen-plus</v-icon>
                      افزودن دسته‌بندی جدید
                    </q-btn>
                  </v-btn-toggle>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredCategories"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>
                        <v-img
                            style="border-radius: 50px;margin-right: -5px;"
                            :src="getImgUrl(item.img)"
                            height="50"
                            width="50"
                        ></v-img>
                      </td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ getBranch(item) }}</td>
                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.active),
          color: getStatusTextColor(item.active),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.active) }}
      </span>
                      </td>


                      <td>
                        <q-btn-group v-if="$q.screen.lt.md">
                          <q-btn
                              small
                              color="primary"
                              @click="openEditCategoryDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn
                              small
                              :color="item.active ? 'grey' : 'success'"
                              @click="toggleActive(item)"
                          >
                            <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.active ? 'غیرفعال شود' : 'فعال شود' }}
                          </q-btn>
                        </q-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditCategoryDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            style="margin-right: 15px"
                            small
                            :color="item.active ? 'grey' : 'success'"
                            @click="toggleActive(item)"
                        >
                          <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.active ? 'غیرفعال شود' : 'فــعـال شــود' }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="editDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedCategory.title }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedCategory.title"
                          label="عنوان دسته‌بندی"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="editedCategory.description"
                          label="توضیحات"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="editedCategory.categoryBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleEditFileUpload"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatus === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatus === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'error'">ناموفق</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newCategoryDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewCategoryDialog">mdi-close</v-icon>
                <span class="headline">افزودن دسته‌بندی جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newCategoryData.title"
                          label="عنوان دسته‌بندی"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="newCategoryData.description"
                          label="توضیحات"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="newCategoryData.categoryBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleFileUpload"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatus === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatus === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'error'">ناموفق</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewCategory">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="400"
        style="font-family: 'fontBody', sans-serif;font-size: 18px"
    >
      <v-card>
        <v-card-title class="headline">حذف دسته‌بندی</v-card-title>
        <v-card-text>آیا از حذف دسته‌بندی "{{ categoryToDelete.title }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteCategory">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'CategoryManagement',
  data() {
    return {
      isUploading: false,
      uploadStatus: null,
      deleteDialog: false,
      categoryToDelete: null,
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      branchTypes: [
        {
          value: 'COFFEE_SHOP',
          title: 'کافی شاپ'
        },
        {
          value: 'RESTAURANT',
          title: 'رستوران'
        }
      ],
      search: '',
      filterStatus: 'all',
      editDialog: false,
      newCategoryDialog: false,
      editedIndex: -1,
      newCategoryData: {
        title: '',
        description: '',
        imgNewCategoryUrl: '',
        imgNewCategoryFileId: ''
      },
      editedCategory: {
        id: 1,
        title: '',
        description: '',
        img: '',
        active: true,
        imgNewCategoryUrl: '',
        imgNewCategoryFileId: ''
      },
      headers: [
        {
          title: 'شناسه',
          align: 'start',
          sortable: false,
          value: 'id',
        }, {
          title: 'تصویر',
          align: 'start',
          sortable: false,
          value: 'img',
        },
        {
          title: 'عنوان',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          title: 'توضیحات',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          title: 'بخش',
          align: 'start',
          sortable: true,
          value: 'categoryBranch',
        },
        {
          title: 'وضعیت',
          align: 'start',
          sortable: true,
          value: 'active',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      categories: [
        {
          id: 1,
          title: 'دسته‌بندی نمونه',
          description: 'توضیحات دسته‌بندی نمونه',
          img: 'http://192.168.222.240:1020/fileUpload/files/3827a9c6-d887-479e-af0d-c713bd8811c3-category.jpg',
          active: true,
        }
      ],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils,
  },
  computed: {
    filteredCategories() {
      let filtered = this.categories
      if (this.filterStatus === 'active') {
        filtered = filtered.filter((category) => category.active)
      } else if (this.filterStatus === 'inactive') {
        filtered = filtered.filter((category) => !category.active)
      }
      return filtered
    },
  },
  methods: {
    hasModule(moduleCode) {
      let has = this.$root.hasModule(moduleCode);
      return has;
    },
    getBranch(item) {
      if (item.categoryBranch) {
        return item.categoryBranch.title;
      } else {
        return '';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case true:
          return '#D4EDDA';
        case false:
          return '#F8D7DA';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case true:
          return '#155724';
        case false:
          return '#721C24';
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return 'فعال';
        case false:
          return 'غیرفعال';
      }
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      return dateString
    },
    toggleActive(item) {
      let beforaState = item.active;
      const changeActiveCategory = {
        id: item.id,
        title: item.title,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveCategory);
      ApiService.post("/category/changeActive", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedCategories = this.categories.map(category => {
          if (category.id === item.id) {
            return {...category, active: !beforaState};
          }
          return category;
        });
        this.categories = updatedCategories;

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    openDeleteDialog(category) {
      this.categoryToDelete = category;
      this.deleteDialog = true;
    },
    confirmDeleteCategory() {
      const deleteCategory = {
        id: this.categoryToDelete.id,
        title: this.categoryToDelete.title,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteCategory);

      console.log(requestBody)
      ApiService.post("/category/delete", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.deleteCategory(this.categoryToDelete);
        this.deleteDialog = false;
        console.log(response)
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteCategory(category) {
      const index = this.categories.findIndex(c => c.id === category.id);
      if (index !== -1) {
        this.categories.splice(index, 1);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.uploadFile(file);
    },
    handleEditFileUpload(event) {
      const file = event.target.files[0];
      this.uploadEditFile(file);
      this.uploadStatus = null;
    },
    getImgUrl(img) {
      return ApiService.getBaseUrl() + "/" + img;
    },
    uploadFile(file) {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "category");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        this.newCategoryData.imgNewCategoryUrl = res.imgUpUrl
        this.newCategoryData.imgNewCategoryFileId = res.imgFileId
        this.uploadStatus = 'success';
        this.isUploading = false;
        setTimeout(() => {
          this.uploadStatus = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatus = 'error';
        this.isUploading = false;
      });
    },
    uploadEditFile(file) {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "category");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        console.log(res)
        this.editedCategory.imgNewCategoryUrl = res.imgUpUrl
        this.editedCategory.imgNewCategoryFileId = res.imgFileId
        this.uploadStatus = 'success';
        this.isUploading = false;
        setTimeout(() => {
          this.uploadStatus = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatus = 'error';
        this.isUploading = false;
      });
    },
    updateImage(file) {
      console.log("updateImage : ", file)
      this.editedCategory.image = file;
    },
    openEditCategoryDialog(category) {
      console.log("openEditCategoryDialog : ", category)
      this.editedIndex = this.categories.indexOf(category)
      this.editedCategory = Object.assign({}, category)
      console.log("this.editedCategory : ", this.editedCategory)

      this.editDialog = true
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory)
        this.editedIndex = -1
      })
    },
    closeNewCategoryDialog() {
      this.newCategoryDialog = false
    },
    openNewCategoryDialog() {
      this.newCategoryDialog = true
    },
    saveEdit() {
      console.log("saveEdit : ", this.editedCategory)
      let editedCategoryData = this.editedCategory;

      if (!this.editedCategory.categoryBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      const newEditCategoryData = {
        id: editedCategoryData.id,
        title: editedCategoryData.title,
        description: editedCategoryData.description,
        imgFileId: editedCategoryData.imgNewCategoryFileId,
        categoryBranch: editedCategoryData.categoryBranch.value
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditCategoryData);
      console.log(requestBody)
      ApiService.post("/category/update", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()

        if (this.editedIndex > -1) {
          let editCat = this.editedCategory;
          if (this.editedCategory.imgNewCategoryUrl) {
            editCat.img = this.editedCategory.imgNewCategoryUrl;
          }
          Object.assign(this.categories[this.editedIndex], editCat)
        }

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    async saveNewCategory() {
      if (this.newCategoryData.imgNewCategoryUrl === '') {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا عکس دسته‌بندی را انتخاب کنید");
        return;
      }
      if (!this.newCategoryData.categoryBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      const newCategoryData = {
        title: this.newCategoryData.title,
        description: this.newCategoryData.description,
        imgFileId: this.newCategoryData.imgNewCategoryFileId,
        categoryBranch: this.newCategoryData.categoryBranch.value
      };
      // let newImgUrl = this.newCategoryData.imgNewCategoryUrl;

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newCategoryData);
      console.log(requestBody)
      ApiService.post("/category/create", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log("category/create ", response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewCategoryDialog();
        this.newCategoryDataValue();

        this.categories.unshift(response.data)

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    newCategoryDataValue() {
      this.newCategoryData = {
        title: '',
        description: '',
        imgNewCategoryUrl: '',
        imgNewCategoryFileId: ''
      };
    },
    getCategoryListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/category/getAllCategory", this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(response)
        this.categories = response.categoryList;
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(error)
      })
    }
  },
  mounted() {
    this.getCategoryListFromServer();
  },
}
</script>
