<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4">
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" style="margin-top: 10px;border-radius: 0px">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background: #343941;color: #9da7b6"
                    >همه
                    </v-btn>
                    <v-btn
                        value="SUBMITTED"
                        :class="{ 'active': filterStatus === 'SUBMITTED' }"
                        style="background: #343941;color: #9da7b6"
                    >در انتظار
                    </v-btn>
                    <v-btn
                        value="DOING"
                        :class="{ 'active': filterStatus === 'DOING' }"
                        style="background: #343941;color: #9da7b6"
                    >در حال انجام
                    </v-btn>
                    <v-btn
                        value="COMPLETED"
                        :class="{ 'active': filterStatus === 'COMPLETED' }"
                        style="background: #343941;color: #9da7b6"
                    >تکمیل شده
                    </v-btn>
                    <v-btn
                        value="CANCELED"
                        :class="{ 'active': filterStatus === 'CANCELED' }"
                        style="background: #343941;color: #9da7b6"
                    >لغو شده
                    </v-btn>

                    <v-select
                        v-model="selectedBranch"
                        :items="branchTypes"
                        :disabled="disableDeskSelect"
                        item-title="title"
                        item-value="value"
                        outlined
                        dense
                        @update:model-value="handleChangeBranch"
                        style="font-family: 'fontBody', sans-serif;border-radius: 15px; font-size: 18px;background: #9da7b6"
                    >
                      <template v-slot:item="{ props,item }">
                        <v-list-item
                            v-bind="props"
                            style="font-family: 'fontBody', sans-serif; font-size: 18px"
                            :style="{
              color: getStatusTextColor(item.value)
            }"
                        >


                        </v-list-item>
                      </template>
                    </v-select>

                    <v-select
                        v-model="selectedDesk"
                        :items="filteredDeskOptions"
                        :disabled="disableDeskSelect"
                        item-title="text"
                        item-value="value"
                        outlined
                        dense
                        :class="{ 'animate-color': shouldAnimate }"
                        @update:model-value="handleDeskChange"
                        style="margin-right:15px;font-family: 'fontBody', sans-serif;border-radius: 15px; font-size: 18px;background: #9da7b6"
                    >
                      <template v-slot:item="{ props,item }">
                        <v-list-item
                            v-bind="props"
                            style="font-family: 'fontBody', sans-serif; font-size: 18px"
                            :style="{
              color: getStatusTextColor(item.value)
            }"
                        >


                        </v-list-item>
                      </template>
                    </v-select>

                  </v-btn-toggle>
                </v-card-title>

                <v-data-table-server
                    :headers="headers"
                    :items="filteredOrders"
                    :search="search"
                    :items-length="totalOrderItems.toString()" page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                    v-model:page="currentPage"
                    @update:options="loadOrders"
                >
                  <template v-slot:item="{ item }">
                    <tr
                        :class="{
      'new-order-animation': item.status === 'SUBMITTED'
    }"
                    >
                      <td>{{ item.id }}</td>
                      <td>{{ item.message }}</td>

                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.status),
          color: getStatusTextColor(item.status),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.status) }}
      </span>
                      </td>

                      <td>
                        <div v-for="info in item.infos" :key="info.id">
                          {{ info.title }} ({{ info.count }})
                        </div>
                      </td>

                      <td v-if="!item.totalPrice">
                        <span>سفارش جدید دارد</span>
                      </td>

                      <td v-if="item.totalPrice">


                        {{ convertNumberToFarsi(item.totalPrice, true) + " تومان " }}
                        <div style="margin-top: 5px;">
  <span v-if="!item.paid" @click="openPaymentDialog(item)"
        :style="{

          backgroundColor: '#D4EDDA',
          color: '#155724',
          padding: '4px 8px',
           marginTop:'3px',
          borderRadius: '4px'
          ,
          cursor: 'pointer'
        }"
  >
        پرداخت
      </span>

                          <span v-if="!item.paid" @click="openManualPaymentDialog(item)"
                                :style="{
          backgroundColor: '#FFF3CD',
          color: '#856404',
          marginRight:'3px',
          marginTop:'3px',
          padding: '4px 8px',
          borderRadius: '4px',
          cursor: 'pointer'
        }"
                          >
        ویرایش مبلغ
      </span>

                        </div>

                      </td>
                      <td>
                        {{ item.desk.name }} ({{ item.desk.number }})
                      </td>
                      <td>
                        <div>
                          <span> ثبت شده :</span>
                          {{ item.timeFormat.betweenFromDate }}
                        </div>

                        <div>
                          <span>بروز شده :</span>
                          {{ item.timeFormat.betweenLastUpdateDate }}
                        </div>

                        <div>
                          <span>توسط :</span>
                          {{ item.lastUpdateUser }}
                        </div>
                      </td>


                      <td>
                        <v-select
                            v-if="item.status !=null && item.status !== 'COMPLETED' && item.status !== 'CANCELED'"
                            v-model="item.status"
                            :items="statusOptions"
                            :disabled="isLoadingStatusOrder"
                            @update:model-value="updateStatusOrder($event,item)"
                            item-title="title"
                            item-value="code"
                            outlined
                            dense

                            style="font-family: 'fontBody', sans-serif; font-size: 18px"
                        >
                          <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                style="font-family: 'fontBody', sans-serif; font-size: 18px"
                                :style="{
              color: getStatusTextColor(item.value)
            }"
                            >

                            </v-list-item>
                          </template>
                        </v-select>

                        <v-btn text="جزییات" @click="openOrderDetailsDialog(item)">

                        </v-btn>
                      </td>
                    </tr>
                  </template>


                </v-data-table-server>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <UiUtils ref="uiUtils"/>
    </div>


    <v-dialog v-model="paymentDialog" persistent max-width="400"
              style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <v-card>
        <v-card-title class="headline">پرداخت</v-card-title>
        <v-card-text>
          آیا مبلغ {{ convertNumberToFarsi(paymentItem.totalPrice, true) + ' تومان' }} ،شامل:
          <div style="background-color: #f5f5f5; padding: 10px; border-radius: 4px; font-size: 14px;">
            <ul style="list-style-type: none; padding: 0; margin: 0;">
              <li v-for="info in paymentItem.infos" :key="info.title" style="margin-bottom: 5px;">
                <v-icon small style="margin-right: 5px;">mdi-circle-small</v-icon>
                {{ info.title }} ({{ convertNumberToFarsi(info.count, true) }}) به مبلغ
                {{ convertNumberToFarsi(info.orderInfoPrice, true) + ' تومان' }}

                <span v-if="info.canceled"
                      :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}

  </span>

              </li>

              <li v-for="info in paymentItem.oldInfos" :key="info.title" style="margin-bottom: 5px;">
                <v-icon small style="margin-right: 5px;">mdi-circle-small</v-icon>
                {{ info.title }} ({{ convertNumberToFarsi(info.count, true) }}) به مبلغ
                {{ convertNumberToFarsi(info.orderInfoPrice, true) + ' تومان' }}

                <span v-if="info.canceled"
                      :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}

  </span>

              </li>
            </ul>
          </div>
          برای میز شماره {{ paymentItem.deskNum }} پرداخت شد؟
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePaymentDialog">نه</v-btn>
          <v-btn color="error" text @click="markAsPaidItem">بله</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manualPaymentDialog" persistent max-width="500"
              style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <v-card>
        <v-card-title class="headline">پرداخت</v-card-title>
        <v-card-text>
          <div>
            <v-text-field
                v-model="editedTotalPrice"
                :label="'مبلغ قابل پرداخت (تومان)'"
                type="number"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="cashReceived"
                :label="'مبلغ دریافتی نقد (تومان)'"
                type="number"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="payNote"
                :label="'یادداشت پرداخت'"
                outlined
                dense
            ></v-text-field>
          </div>
          <div v-if="paymentItem">
            <div>
              <span>مبلغ + نقد : </span>
              <span>{{ convertNumberToFarsi(calculationTotalPriceCashReceived(), true) }} تومان</span>
            </div>

            <div>
              <div :style="{ color: calculatePriceDifference().color }">
                {{ calculatePriceDifference().message }}
              </div>
              <div v-if="getPriceDifferencePercentage() !== '0.00'">
                درصد تفاوت قیمت: {{ getPriceDifferencePercentage() }}%
              </div>
            </div>

            مبلغ فاکتور : {{ convertNumberToFarsi(paymentItem.totalPrice, true) + ' تومان' }} ،شامل:
            <div style="background-color: #f5f5f5; padding: 10px; border-radius: 4px; font-size: 14px;">
              <ul style="list-style-type: none; padding: 0; margin: 0;">
                <li v-for="info in paymentItem.infos" :key="info.title" style="margin-bottom: 5px;">
                  <v-icon small style="margin-right: 5px;">mdi-circle-small</v-icon>
                  {{ info.title }} ({{ convertNumberToFarsi(info.count, true) }}) به مبلغ
                  {{ convertNumberToFarsi(info.orderInfoPrice, true) + ' تومان' }}

                  <span v-if="info.canceled"
                        :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}
  </span>

                </li>

                <li v-for="info in paymentItem.oldInfos" :key="info.title" style="margin-bottom: 5px;">
                  <v-icon small style="margin-right: 5px;">mdi-circle-small</v-icon>
                  {{ info.title }} ({{ convertNumberToFarsi(info.count, true) }}) به مبلغ
                  {{ convertNumberToFarsi(info.orderInfoPrice, true) + ' تومان' }}

                  <span v-if="info.canceled"
                        :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}

  </span>
                </li>

              </ul>
            </div>
            برای میز شماره {{ paymentItem.deskNum }} پرداخت شد؟
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeManualPaymentDialog">نه</v-btn>
          <v-btn color="error" text @click="markAsManualPaidItem">بله</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderDetailsDialog" persistent max-width="850"
              style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <v-card class="order-details-card">
        <v-card-title class="headline primary-color">
          <span class="title-text">جزئیات سفارش شماره {{ orderDetails.id }}</span>
          <v-spacer></v-spacer>
          <v-icon class="close-icon" @click="closeOrderDetailsDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="order-details-content">
          <div class="order-info">
            <table>
              <tr>
                <th>عنوان</th>
                <th>مقدار</th>
              </tr>
              <tr>
                <td><strong>وضعیت سفارش:</strong></td>
                <td>
                    <span
                        :style="{
                       backgroundColor: getStatusColor(orderDetails.status),
                       color: getStatusTextColor(orderDetails.status),
                       padding: '4px 8px',
                       borderRadius: '4px'
                     }"
                    >
                          {{ getStatusText(orderDetails.status) }}
                    </span>
                </td>
              </tr>
              <tr class="alt">
                <td><strong>مبلغ کل:</strong></td>
                <td>{{ convertNumberToFarsi(orderDetails.totalPrice, true) }} تومان</td>
              </tr>
              <tr>
                <td><strong>تخفیف:</strong></td>
                <td>{{ convertNumberToFarsi(orderDetails.offer, true) }} تومان</td>
              </tr>
              <tr class="alt">
                <td><strong>زمان سفارش:</strong></td>
                <td>{{ orderDetails.persianFromDate }}</td>
              </tr>
              <tr>
                <td><strong>بروزرسانی:</strong></td>
                <td>{{ orderDetails.persianLastUpdateDate }}</td>
              </tr>
              <tr>
                <td><strong>بروز شده توسط:</strong></td>
                <td>{{ orderDetails.lastUpdateUser }}</td>
              </tr>
              <tr class="alt">
                <td><strong>میز:</strong></td>
                <td>{{ orderDetails.desk.name }} (شماره {{ orderDetails.desk.number }})</td>
              </tr>
              <tr>
                <td><strong>یادداشت:</strong></td>
                <td>{{ orderDetails.note }}</td>
              </tr>
              <tr class="alt">
                <td><strong>یادداشت پرداخت:</strong></td>
                <td>{{ orderDetails.payNote }}</td>
              </tr>
              <tr>
                <td><strong>وضعیت پرداخت:</strong></td>
                <td><span class="payment-badge" :class="orderDetails.paid ? 'paid' : 'unpaid'">{{
                    orderDetails.paid ? 'پرداخت شده' : 'پرداخت نشده'
                  }}</span></td>
              </tr>
              <tr class="alt" v-if="orderDetails.paid">
                <td><strong>مبلغ پرداخت شده:</strong></td>
                <td>{{ convertNumberToFarsi(orderDetails.paymentReceived, true) }} تومان</td>
              </tr>
            </table>
          </div>
          <div class="order-items" style="font-size: 16px;">
            <h3 class="section-title">آیتم‌های سفارش:</h3>
            <v-list class="order-item-list">
              <v-list-item v-for="info in orderDetails.infos" :key="info.id" class="order-item">
                <v-list-item-icon class="order-item-icon">
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ info.title }} (تعداد: {{
                      convertNumberToFarsi(info.count, true)
                    }})

                    <span v-if="info.canceled"
                          :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}
  </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>قیمت واحد: {{ convertNumberToFarsi(info.price, true) }} تومان | قیمت کل:
                    {{ convertNumberToFarsi(info.orderInfoPrice, true) }} تومان
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>


            <h3 v-if="orderDetails.oldInfos != null && orderDetails.oldInfos.length > 0" class="section-title">سفارش‌های
              قبلی میز:</h3>
            <v-list v-if="orderDetails.oldInfos != null && orderDetails.oldInfos.length > 0" class="order-item-list">
              <v-list-item v-for="info in orderDetails.oldInfos" :key="info.id" class="order-item">
                <v-list-item-icon class="order-item-icon">
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ info.title }} (تعداد: {{
                      convertNumberToFarsi(info.count, true)
                    }})

                    <span v-if="info.canceled"
                          :style="{
          backgroundColor: getStatusColor('CANCELED'),
          color: getStatusTextColor('CANCELED'),
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.8rem',
          marginRight: '8px'
        }">
    {{ getStatusText('CANCELED') }}
  </span>

                  </v-list-item-title>
                  <v-list-item-subtitle>قیمت واحد: {{ convertNumberToFarsi(info.price, true) }} تومان | قیمت کل:
                    {{ convertNumberToFarsi(info.orderInfoPrice, true) }} تومان
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>زمان: {{ info.fromDate }} | {{ info.betweenFromDate }}</v-list-item-subtitle>
                  <v-list-item-subtitle>شناسه سفارش: {{ info.orderId }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>


  </v-locale-provider>
</template>

<script>
import UiUtils from "@/components/util/UiUtils.vue";
import ApiService from "@/services/ApiService";
import * as persianHelpers from "persian-helpers";
import {useQuasar} from "quasar";

export default {
  name: 'OrderManagement',
  components: {UiUtils},
  data() {
    return {
      shouldAnimate: false,
      branchTypes: [
        {
          value: 'ALL',
          title: 'همه بخش ها'
        },
        {
          value: 'COFFEE_SHOP',
          title: 'کافی شاپ'
        },
        {
          value: 'RESTAURANT',
          title: 'رستوران'
        }
      ],
      totalOrderItems: 2,
      search: '',
      orderTableIsLoading: false,
      disableDeskSelect: false,
      timerRefresh: null,
      isLoadingStatusOrder: false,
      newStatus: null,
      notification: null,
      notificationShowing: false,
      firstLoadedData: true,
      paymentDialog: false,
      manualPaymentDialog: false,
      editedTotalPrice: 0,
      currentPage: 1,
      payNote: '',
      cashReceived: 0,
      filterStatus: 'all',
      selectedDesk: 'all',
      selectedBranch: 'همه بخش ها',
      deskOptions: [
        {
          value: 'all',
          text: 'همه میزها',
          deskBranch: ''
        }
      ],
      orderDetailsDialog: false,
      orderDetails: {},
      paymentItem: {
        id: 1,
        totalPrice: 0,
        deskName: '',
        deskNum: '',
        infos: [
          {title: '', price: '', count: ''}
        ],
        oldInfos: [{
          title: '', price: '', count: ''
        }]
      },
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'}
      ],
      statusOptions: [
        {code: 'SUBMITTED', title: 'در انتظار'},
        {code: 'DOING', title: 'در حال انجام'},
        {code: 'COMPLETED', title: 'تکمیل شده'},
        {code: 'CANCELED', title: 'لغو شده'},
      ],
      headers: [
        {title: 'شناسه', align: 'start', value: 'id'},
        {title: 'پیام', align: 'start', value: 'message'},
        {title: 'وضعیت', align: 'start', value: 'status'},
        {title: 'جزئیات سفارش', align: 'start', value: 'orderDetails', sortable: false},
        {title: 'قیمت کل', align: 'start', value: 'totalPrice'},
        {title: 'میز', align: 'start', value: 'desk', sortable: false},
        {title: 'تاریخ', align: 'start', value: 'betweenTimeMinutes'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      orders: [
        {
          id: 1118,
          message: "پیاک بارگذاری",
          status: "SUBMITTED",
          totalPrice: 170000,
          offer: 0,
          betweenTimeMinutes: '',
          persianFromDate: '',
          persianLastUpdateDate: '',
          note: 'یاداشت مدیریت',
          desk: {
            id: 1002,
            name: "میز شماره دو",
            userCount: 6,
            number: 2,
            active: true
          },
          paid: false,
          paymentReceived: 0,
          payNote: '',
          fromDate: "2024-04-01T11:23:17.205159",
          lastUpdateDate: "2024-04-01T11:23:17.205159",
          lastUpdateUser: "میز شماره دو",
          timeFormat: {
            fromDate: null,
            betweenFromDate: "۱۰ ساعت پیش",
            lastUpdateDate: null,
            betweenLastUpdateDate: "۱۰ ساعت پیش"
          },
          infos: [
            {
              id: 1143,
              title: "تست",
              price: 30000,
              orderInfoPrice: 90000,
              count: 3
            },
            {
              id: 1144,
              title: "شاپینگو",
              price: 40000,
              orderInfoPrice: 80000,
              count: 2
            }
          ],
          oldInfos: [
            // {
            // id: 1198,
            // title: "همراه دسته بندی",
            // price: 40000,
            // orderInfoPrice: 40000,
            // count: 1,
            // fromDate: " پنجشنبه، ۰۹:۲۴:۰۹",
            // orderId: 1157
            // }
          ]
        }
      ],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
      itemPerPage: 10,
      q: useQuasar(),
    }
  },
  computed: {
    filteredOrders() {
      let filtered = this.orders
      if (this.filterStatus === 'SUBMITTED') {
        filtered = filtered.filter(order => order.status === 'SUBMITTED')
      } else if (this.filterStatus === 'COMPLETED') {
        filtered = filtered.filter(order => order.status === 'COMPLETED')
      } else if (this.filterStatus === 'CANCELED') {
        filtered = filtered.filter(order => order.status === 'CANCELED')
      } else if (this.filterStatus === 'DOING') {
        filtered = filtered.filter(order => order.status === 'DOING')
      }
      // if (this.selectedDesk !== 'all') {
      //   filtered = filtered.filter(order => order.desk.id === this.selectedDesk);
      // }
      console.log("this.selectedDesk : ", this.selectedDesk)
      return filtered
    },
    filteredDeskOptions() {
      if (this.selectedBranch === 'همه بخش ها' || this.selectedBranch === 'ALL') {
        return this.deskOptions;
      } else {
        let result = this.deskOptions.filter(desk => {
          if (desk.deskBranch) {
            return desk.deskBranch === this.selectedBranch;
          }
          return false;
        });
        result.unshift({
          value: 'all',
          text: 'همه میزها',
          deskBranch: ''
        });
        return result;
      }
    }
  },
  methods: {
    handleChangeBranch() {
      this.selectedDesk = 'all';
      this.disableDeskSelect = true;
      this.resetPage();
      this.showNotification();
      this.getOrderListFromServer().then(() => {
        this.hideNotification();
      });
    },
    resetPage() {
      this.currentPage = 1;
    },
    showNotification() {
      if (this.notificationShowing)
        return;
      this.notification = this.$q.notify({
        group: false,
        spinner: true,
        type: 'info',
        message: 'لطفا صبر کنید ...',
        classes: 'font-progress',
        position: 'top',
        timeout: 0
      })
      this.notificationShowing = true;
    },
    hideNotification() {
      this.notificationShowing = false;
      if (this.notification) {
        this.notification({
          timeout: 0.1,
          spinner: false,
          icon: 'done',
        })
        this.notification = null
      }
    },
    loadOrders(opt) {
      console.log("k : ", opt)
      let size = opt.itemsPerPage;
      let page = opt.page - 1;
      this.itemPerPage = size;
      this.showNotification();
      let deskId = 0;
      if (this.selectedDesk !== 'all') {
        deskId = this.selectedDesk;
      }
      ApiService.get("/order/orders?page=" + page + "&size=" + size + "&deskId=" + deskId, this.config).then(response => {
        this.orderTableIsLoading = false;
        console.log(response)
        this.orders = response.orders;
        this.totalOrderItems = response.allOrdersCount;
        if (this.firstLoadedData) {
          this.firstLoadedData = false;
        }
        this.hideNotification();
        this.orderTableIsLoading = false;
      }).catch(error => {
        this.orderTableIsLoading = false;
        this.hideNotification();
        console.log(error)
        this.q.notify({
          type: 'negative',
          html: true,
          classes: "notify-font",
          message: error.message,
          position: 'bottom',
          progress: true
        })
      })

    },
    handleDeskChange() {
      this.disableDeskSelect = true;
      this.resetPage();
      this.showNotification();
      this.getOrderListFromServer().then(() => {
        this.hideNotification();
        if (this.selectedDesk !== 'all') {
          this.deskSelectionTimer = setTimeout(() => {
            this.shouldAnimate = true;
          }, 4000);
        } else {
          clearTimeout(this.deskSelectionTimer);
        }
      });
    },
    handleBranchChange() {
      this.filteredDeskOptions();
      this.disableDeskSelect = true;
      this.resetPage();
      this.showNotification();
      this.getOrderListFromServer().then(() => {
        this.hideNotification();
        if (this.selectedDesk !== 'all') {
          this.deskSelectionTimer = setTimeout(() => {
            this.selectedDesk = 'all';
          }, 50000);
        } else {
          clearTimeout(this.deskSelectionTimer);
        }
      });
    },
    openOrderDetailsDialog(orderItem) {
      this.orderDetailsDialog = true;
      this.orderDetails = orderItem;
    },
    closeOrderDetailsDialog() {
      this.orderDetailsDialog = false;
      // this.orderDetails = null;
    },
    updateStatusOrder(eve, item) {
      console.log("item : ", item.id)
      this.isLoadingStatusOrder = true;

      const data = {
        id: item.id,
        status: eve
      };
      this.$refs.uiUtils.showIsLoading()
      const requestBody = JSON.stringify(data);

      ApiService.post("/order/updateStatus", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.$refs.uiUtils.showSuccessDialog()
        console.log(response)
        this.isLoadingStatusOrder = false;
      }).catch(error => {

        this.$refs.uiUtils.dismissIsLoading()
        this.isLoadingStatusOrder = false;
        this.$refs.uiUtils.showError(error.message);
        console.log(error)
      })
    },
    openPaymentDialog(item) {
      this.paymentDialog = true;
      this.paymentItem = {
        id: item.id,
        totalPrice: item.totalPrice,
        deskName: item.desk.name,
        deskNum: item.desk.number,
        infos: item.infos,
        oldInfos: item.oldInfos
      }
      console.log(item)
    },
    calculatePriceDifference() {
      const totalPrice = Number(this.paymentItem.totalPrice);
      const cashReceived = Number(this.calculationTotalPriceCashReceived());
      const priceDifference = cashReceived - totalPrice;

      if (priceDifference > 0) {
        return {
          message: `مبلغ دریافتی ${this.convertNumberToFarsi(Math.abs(priceDifference), true)} تومان بیشتر از مبلغ کل است.`,
          color: 'green'
        };
      } else if (priceDifference < 0) {
        return {
          message: `مبلغ دریافتی ${this.convertNumberToFarsi(Math.abs(priceDifference), true)} تومان کمتر از مبلغ کل است.`,
          color: 'red'
        };
      } else {
        return {
          message: 'مبلغ دریافتی با مبلغ کل برابر است.',
          color: 'black'
        };
      }
    },
    getPriceDifferencePercentage() {
      const totalPrice = Number(this.paymentItem.totalPrice);
      const cashReceived = Number(this.calculationTotalPriceCashReceived());
      const priceDifference = cashReceived - totalPrice;

      if (totalPrice === 0) {
        return 0;
      }

      const percentageDifference = (priceDifference / totalPrice) * 100;
      return percentageDifference.toFixed(2);
    },
    openManualPaymentDialog(item) {
      this.manualPaymentDialog = true;
      this.paymentItem = {
        id: item.id,
        totalPrice: item.totalPrice,
        deskName: item.desk.name,
        deskNum: item.desk.number,
        infos: item.infos,
        oldInfos: item.oldInfos
      }
      this.editedTotalPrice = this.paymentItem.totalPrice
      console.log(item)
    },
    calculationTotalPriceCashReceived() {
      return Number(this.editedTotalPrice) + Number(this.cashReceived);
    },
    closePaymentDialog() {
      this.paymentDialog = false;
      this.paymentItem = {}
    },
    closeManualPaymentDialog() {
      this.manualPaymentDialog = false;
      this.editedTotalPrice = 0,
          this.cashReceived = 0,
          this.paymentItem = {}
    },
    markAsPaidItem() {
      this.$refs.uiUtils.showIsLoading()

      const payment = {
        id: this.paymentItem.id,
        paymentReceived: this.paymentItem.totalPrice,
        paid: true,
        cashReceived: 0,
        payNote: ''
      };
      const requestBody = JSON.stringify(payment);

      ApiService.post("/order/orderPay", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.orders = response.orders;
        this.closePaymentDialog();
        this.$refs.uiUtils.showSuccessDialog()
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
          this.$refs.uiUtils.showError(error.message);
        }
        console.log(error)
      })
    },
    markAsManualPaidItem() {
      this.$refs.uiUtils.showIsLoading()

      const payment = {
        id: this.paymentItem.id,
        paymentReceived: Number(this.calculationTotalPriceCashReceived()),
        paid: true,
        cashReceived: Number(this.cashReceived),
        payNote: this.payNote
      };
      const requestBody = JSON.stringify(payment);

      ApiService.post("/order/orderPay", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.orders = response.orders;
        this.closeManualPaymentDialog();
        this.$refs.uiUtils.showSuccessDialog()
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
          this.$refs.uiUtils.showError(error.message);
        }
        console.log(error)
      })
    },
    convertNumberToFarsi(price, sep) {
      return persianHelpers.convertDigits(price, {separator: sep});
    },
    getStatusText(status) {
      switch (status) {
        case 'SUBMITTED':
          return 'در انتظار';
        case 'COMPLETED':
          return 'تکمیل شده';
        case 'CANCELED':
          return 'کنسل شده';
        case 'DOING':
          return 'در حال انجام';
        default:
          return status;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'SUBMITTED':
          return '#FFF3CD';
        case 'COMPLETED':
          return '#D4EDDA';
        case 'CANCELED':
          return '#F8D7DA';
        case 'DOING':
          return '#D1ECF1';
        default:
          return '#FFFFFF';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case 'SUBMITTED':
          return '#856404';
        case 'COMPLETED':
          return '#155724';
        case 'CANCELED':
          return '#721C24';
        case 'DOING':
          return '#0C5460';
        default:
          return '#000000';
      }
    },
    getOrderListFromServer() {
      return new Promise(resolve => {
        if (this.currentPage !== 1) {
          return;
        }
        if (this.firstLoadedData) {
          this.$refs.uiUtils.showIsLoading()
        }
        let deskId = 0;
        if (this.selectedDesk !== 'all') {
          deskId = this.selectedDesk;
        }
        console.log("selectedBranch : ", this.selectedBranch)
        let url = '';
        if (this.selectedBranch === 'همه بخش ها' || this.selectedBranch === 'ALL') {
          url = "/order/orders?page=" + 0 + "&size=" + this.itemPerPage + "&deskId=" + deskId;
        } else {
          url = "/order/orders?page=" + 0 + "&size=" + this.itemPerPage + "&deskId=" + deskId + "&branch=" + this.selectedBranch;
        }
        ApiService.get(url, this.config).then(response => {
          this.disableDeskSelect = false;
          if (this.firstLoadedData) {
            this.$refs.uiUtils.dismissIsLoading()
          }
          console.log(response)
          this.orders = response.orders;
          this.totalOrderItems = response.allOrdersCount;
          if (this.firstLoadedData) {
            this.firstLoadedData = false;
          }
          resolve();
        }).catch(error => {
          this.disableDeskSelect = false;
          resolve();
          if (this.firstLoadedData) {
            this.$refs.uiUtils.dismissIsLoading()
          }
          console.log(error)
          this.q.notify({
            type: 'negative',
            html: true,
            classes: "notify-font",
            message: error.message,
            position: 'bottom',
            progress: true
          })
        })
      });
    },
    getDeskListFromServer() {
      this.showNotification()
      ApiService.get("/desk/getAllDeskList", this.config).then(response => {
        this.hideNotification();
        let desks = response.deskList;
        this.deskOptions = [
          {value: 'all', text: 'همه میزها'},
          ...desks.map(desk => ({
            value: desk.id,
            text: `میز شماره ${desk.number}`,
            deskBranch: desk.deskBranch.value
          }))
        ];
      }).catch(error => {
        this.hideNotification();
        console.log(error)
      })
    },
    startOrderUpdateTimer() {
      this.timerRefresh = setInterval(() => {
        this.getOrderListFromServer()
      }, 12000)
    },
    stopOrderUpdateTimer() {
      clearInterval(this.timerRefresh)
    }
  },
  mounted() {
    // this.getOrderListFromServer();
    this.startOrderUpdateTimer();
    this.getDeskListFromServer();
  },
  beforeUnmount() {
    this.stopOrderUpdateTimer();
    this.hideNotification();
  }

}
</script>
<style>
.font-progress {
  font-family: 'fontBody', sans-serif;
}

.v-btn-toggle > .v-btn.active {
  background-color: #ffffff !important;
  border-radius: 5px;
  color: #000000 !important;
}

.new-order-animation {
  animation: new-order-highlight 3s infinite;
}

@keyframes new-order-highlight {
  0% {
    background-color: #fff3cd;
  }
  25% {
    background-color: #ffa6a6; /* رنگ قرمز کم‌رنگ */
  }
  50% {
    background-color: #f6e2c7; /* رنگ آبی کم‌رنگ */
  }
  75% {
    background-color: #ffa6a6; /* رنگ قرمز کم‌رنگ */
  }
  100% {
    background-color: #fff3cd;
  }
}

.order-details-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: right !important;;
  direction: rtl !important;;

  .primary-color {
    color: #fff;
    background-color: #353942;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;

    .title-text {
      font-size: 1.4rem;
      font-weight: bold;
    }

    .close-icon {
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #d32f2f; /* رنگ قرمز */
      }
    }
  }

  .order-details-content {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    text-align: right !important;;
    direction: rtl !important;;

    .order-info {
      flex: 1 1 50%;
      padding-right: 16px;

      .status-badge {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.9rem;
        font-weight: bold;

        &.completed {
          background-color: #4caf50; /* رنگ سبز */
          color: #fff;
        }

        &.pending {
          background-color: #ffc107; /* رنگ زرد */
          color: #333;
        }

        &.canceled {
          background-color: #d32f2f; /* رنگ قرمز */
          color: #fff;
        }
      }

      .payment-badge {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.9rem;
        font-weight: bold;

        &.paid {
          background-color: #4caf50; /* رنگ سبز */
          color: #fff;
        }

        &.unpaid {
          background-color: #d32f2f; /* رنگ قرمز */
          color: #fff;
        }
      }
    }

    .animate-color {
      animation: color-change 1s infinite alternate;
    }

    @keyframes color-change {
      0% {
        background-color: #9da7b6; /* رنگ اولیه */
      }
      100% {
        background-color: #ff6961; /* رنگ انیمیشن شده */
      }
    }

    .order-items {
      flex: 1 1 50%;
      padding-left: 0px;
      padding-right: 25px;
      text-align: right !important;;
      direction: rtl !important;;

      .section-title {
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .order-item-list {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .order-item {
          border-bottom: 1px solid #eee;
          padding: 12px;

          &:last-child {
            border-bottom: none;
          }

          .order-item-icon {
            color: #8b5e3c; /* رنگ قهوه‌ای کم‌رنگ */
          }

          .order-item-icon::before {
            font-size: 1.2rem;
          }

          .order-item-icon.v-list-item__icon--avatar {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.order-info table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.order-info th,
.order-info td {
  padding: 0.75rem 1rem;
  text-align: right;
  font-size: 14px;
}

.order-info th {
  background-color: #E0E0E0;
  color: #000;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: 10px;
}

.order-info tr:nth-child(even) {
  background-color: #f5f5f5;
}

.order-info tr:nth-child(odd) {
  background-color: #e0e0e0;
}

.order-info tr:hover {
  background-color: #e8eaf6;
}

</style>
