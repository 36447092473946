<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-select
                      v-model="selectedProduct"
                      :items="products"
                      item-title="name"
                      item-value="id"
                      label="محصول"
                  ></v-select>
                  <v-btn color="primary" @click="calculateProductionCost">
                    محاسبه
                  </v-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="productionCostReport.formulaItemCosts" :search="search"
                              page-text="{0}-{1} از {2}"
                              items-per-page-text="تعداد در صفحه : " :items-per-page-options="probCountInpPage"
                              mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.materialName }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.unitPrice }}</td>
                      <td>{{ item.itemCost }}</td>
                    </tr>
                  </template>
                </v-data-table>

                <v-card-text>
                  <div class="d-flex justify-end">
                    <div class="text-h6">جمع کل هزینه تولید: {{ productionCostReport.totalCost }}</div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>
<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'ProductionCost',
  data() {
    return {
      products: [],
      selectedProduct: null,
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      headers: [
        {title: 'نام ماده اولیه', align: 'start', sortable: true, value: 'materialName'},
        {title: 'مقدار', align: 'start', sortable: true, value: 'quantity'},
        {title: 'قیمت واحد', align: 'start', sortable: true, value: 'unitPrice'},
        {title: 'قیمت آیتم', align: 'start', sortable: true, value: 'itemCost'},
      ],
      productionCostReport: {
        productName: '',
        totalCost: 0,
        formulaItemCosts: []
      },
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    calculateProductionCost() {
      if (!this.selectedProduct) return;

      this.$refs.uiUtils.showIsLoading();

      ApiService.get(`formula/calculateProductionCost?productId=${this.selectedProduct}`, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.productionCostReport = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    getProductsFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/product/getProductsSelect", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.products = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    },
  },
  mounted() {
    this.getProductsFromServer();
  }
}
</script>
