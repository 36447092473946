<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>

                    <q-btn
                        dense
                        outlined
                        small
                        v-if="hasModule('deleteAllChatAdmin')"
                        color="error"
                        @click="openDeleteAllChats"
                        style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;"
                    >
                      <v-icon small>mdi-delete</v-icon>
                      پاک کردن همه
                    </q-btn>
                  </v-btn-toggle>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredChats"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="chatCountInPage"
                    :items-per-page="300"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.text }}</td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.sender }}</td>
                      <td>{{ item.desk?.name || 'ندارد' }} ({{ item.desk?.number || 'ندارد' }})</td>
                      <td>

                        <v-btn-group v-if="$q.screen.lt.md">
                          <v-btn
                              small
                              v-if="hasModule('deleteChatAdmin')"
                              color="error"
                              @click="openDeleteDialog(item)"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </v-btn>

                          <v-btn
                              small
                              v-if="hasModule('confirmChatAdmin') && !item.confirmed"
                              :color="'primary'"
                              @click="confirmChat(item)"
                          >
                            <v-icon small>mdi-check</v-icon>
                            تایید
                          </v-btn>

                        </v-btn-group>

                        <v-btn
                            v-if="hasModule('deleteChatAdmin') && !$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>

                        <v-btn
                            v-if="!$q.screen.lt.md && !item.confirmed"
                            small
                            :color="'primary'"
                            class="mr-2"
                            @click="confirmChat(item)"
                        >
                          <v-icon small>mdi-check</v-icon>
                          تایید
                        </v-btn>

                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="deleteDialog"
              persistent
              max-width="400"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="headline">حذف چت</v-card-title>
              <v-card-text>آیا از حذف چت "{{ chatToDelete.text }}" اطمینان دارید؟</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
                <v-btn color="error" text @click="confirmDeleteChat">حذف</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog
              v-model="deleteAllChatDialog"
              persistent
              max-width="400"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="headline">حذف چت</v-card-title>
              <v-card-text>آیا از حذف همه چت ها اطمینان دارید؟</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="deleteAllChatDialog = false">لغو</v-btn>
                <v-btn color="error" text @click="confirmDeleteAllChat">حذف</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';
import {useQuasar} from "quasar";

export default {
  name: 'ChatManagement',
  data() {
    return {
      deleteDialog: false,
      deleteAllChatDialog: false,
      chatToDelete: null,
      chatCountInPage: [
        {value: 400, title: '400'},
        {value: 600, title: '600'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',
      filterStatus: 'all',
      headers: [
        {
          title: 'شناسه چت',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          title: 'متن چت',
          align: 'start',
          sortable: true,
          value: 'text',
        },
        {
          title: 'ساعت',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {
          title: 'فرستنده',
          align: 'start',
          sortable: true,
          value: 'sender',
        },
        {
          title: 'میز',
          align: 'start',
          sortable: true,
          value: 'desk.name',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      chats: [
        // {
        //   id: 1,
        //   text: 'سلام',
        //   date: '2:46:59 PM',
        //   sender: 'ستاره',
        //   uid: '',
        //   desk: {
        //     id: 1112,
        //     name: 'ستاره',
        //     userCount: 4,
        //     number: 1,
        //     active: false
        //   }
        // }
      ],
      syncedLastChats: false,
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
      timer: null,
      q: useQuasar(),
    }
  },
  components: {
    UiUtils,
  },
  computed: {
    filteredChats() {
      let filtered = this.chats
      // if (this.filterStatus === 'active') {
      filtered = filtered.filter(chat => chat.id)
      // } else if (this.filterStatus === 'inactive') {
      //   filtered = filtered.filter(chat => chat.desk?.active === false)
      // }
      return filtered
    },
  },
  methods: {
    hasModule(moduleCode) {
      let has = this.$root.hasModule(moduleCode);
      return has;
    },
    confirmChat(item) {
      const confirmChat = {
        uid: item.uid
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(confirmChat);
      console.log(requestBody)
      ApiService.post("/chats/confirmChatAdmin", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        item.confirmed = !item.confirmed;
        console.log(response)
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    confirmDeleteAllChat() {
      const deleteChat = {
        uid: "uid"
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteChat);

      console.log(requestBody)
      ApiService.post("/chats/deleteAllChatAdmin", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        // this.deleteChat(this.chatToDelete);
        this.deleteDialog = false;
        console.log(response)
        this.chats = []
        this.deleteAllChatDialog = false;
      }).catch(error => {
        console.log(error)
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    openDeleteAllChats() {
      this.deleteAllChatDialog = true;
    },
    openDeleteDialog(chat) {
      this.chatToDelete = chat;
      this.deleteDialog = true;
    },
    confirmDeleteChat() {
      const deleteChat = {
        uid: this.chatToDelete.uid
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteChat);

      console.log(requestBody)
      ApiService.post("/chats/deleteChatAdmin", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.deleteChat(this.chatToDelete);
        this.deleteDialog = false;
        console.log(response)
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteChat(chat) {
      const index = this.chats.findIndex(c => c.id === chat.id);
      if (index !== -1) {
        this.chats.splice(index, 1);
      }
    },
    toggleDeskActive(desk) {
      let beforeState = desk.active;
      const changeDeskActiveData = {
        id: desk.id,
        name: desk.name,
        active: !beforeState
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeDeskActiveData);
      ApiService.post("/desks/changeActive", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedChats = this.chats.map(chat => {
          if (chat.desk?.id === desk.id) {
            return {...chat, desk: {...chat.desk, active: !beforeState}};
          }
          return chat;
        });
        this.chats = updatedChats;
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);

      })
    },

    getChatListFromServer() {
      if (!this.syncedLastChats)
        this.$refs.uiUtils.showIsLoading()

      let lastChat = this.chats[0];
      let uid = lastChat ? lastChat.uid : '';

      ApiService.get("/chats/getAdminChats?uid=" + uid, this.config).then(response => {
        if (!this.syncedLastChats) {
          if (this.$refs.uiUtils) {
            this.$refs.uiUtils.dismissIsLoading()
          }
        }

        if (response.length > 0) {
          let newChats = response.filter(chat => !this.chats.some(c => c.id === chat.id));
          console.log("newChats uid : ", newChats);
          console.log("newChats response : ", response);
          this.chats = newChats.concat(this.chats);
          console.log("this.chats uid : ", this.chats);
        }
        this.syncedLastChats = true;
      }).catch(error => {
        if (!this.syncedLastChats) {
          if (this.$refs.uiUtils) {
            this.$refs.uiUtils.dismissIsLoading()
          }
        }
        console.log(error)


        this.q.notify({
          type: 'negative',
          html: true,
          classes: "notify-font",
          message: error.message,
          position: 'bottom',
          progress: true

        })

      })
    },


    startChatUpdateTimer() {
      this.timer = setInterval(() => {
        this.getChatListFromServer()
      }, 3000)
    },
    stopChatUpdateTimer() {
      clearInterval(this.timer)
    }
  },
  mounted() {
    this.getChatListFromServer();
    this.startChatUpdateTimer();
  },
  beforeUnmount() {
    this.stopChatUpdateTimer();
  }
}
</script>
