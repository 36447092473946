<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>

                    <q-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewProductDialog"
                        style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;"
                    >
                      <v-icon small>mdi-pen-plus</v-icon>
                      افزودن محصول جدید
                    </q-btn>
                  </v-btn-toggle>


                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredProducts"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"

                    mobile-breakpoint="600"
                >


                  <template v-slot:item="{ item }">

                    <tr>
                      <td>
                        <v-img style="border-radius: 50px;margin-right: -5px;"
                               :src="getImgUrl(item.img)"
                               height="50"
                               width="50"
                        ></v-img>
                      </td>
                      <td>{{ item.title }}</td>
                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.active),
          color: getStatusTextColor(item.active),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.active) }}
      </span>
                      </td>

                      <td> {{ convertNumberToFarsi(item.price, true) + " تومان " }}
                      </td>
                      <td>{{ convertNumberToFarsi(item.inStockNumber, false) }}</td>
                      <td>{{ getBranch(item) }}</td>
                      <td v-if="item.categoryInfo.name">{{ item.categoryInfo.name }}</td>
                      <td v-if="!item.categoryInfo.name">{{ getCategoryNameById(item.categoryInfo) }}</td>

                      <td>
                        <q-btn-group v-if="$q.screen.lt.md">
                          <q-btn

                              small
                              color="primary"
                              @click="openEditProductDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"

                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn

                              small
                              :color="item.active ? 'grey' : 'success'"
                              @click="toggleActive(item)"
                          >
                            <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.active ? 'غیرفعال شود' : 'فعال شود' }}
                          </q-btn>
                        </q-btn-group>


                        <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                               @click="openEditProductDialog(item)">
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2" @click="openDeleteDialog(item)">
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" style="margin-right: 15px" small
                               :color="item.active ? 'grey' : 'success'" @click="toggleActive(item)">
                          <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.active ? 'غیرفعال شود' : 'فــعـال شــود' }}
                        </v-btn>

                      </td>
                    </tr>
                  </template>
                </v-data-table>

              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="editDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedProduct.title }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedProduct.title"
                          label="نام محصول"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model.number="editedProduct.price"
                          label="قیمت (تومان)"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model.number="editedProduct.inStockNumber"
                          label="موجودی"
                          type="number"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="editedProduct.category"
                          :items="categories"
                          item-title="name"
                          item-value="id"
                          @update:model-value="handleEditCategoryChange"
                          :error-messages="categoryErrorMessage"
                          label="دسته بندی"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="editedProduct.productBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="editedProduct.description"
                          textarea
                          label="توضیح محصول"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleEditFileUpload"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatus === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatus === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'error'">ناموفق</span>

                    </v-col>


                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog v-model="newProductDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewProductDialog">mdi-close</v-icon>
                <span class="headline">افزودن محصول جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newProductData.name"
                          label="نام محصول"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model.number="newProductData.price"
                          label="قیمت (تومان)"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model.number="newProductData.availableCount"
                          label="موجودی"
                          type="number"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="newProductData.productParentId"
                          :items="parentProducts"
                          item-title="name"
                          item-value="id"
                          label="محصول مادر"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>


                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="newProductData.category"
                          :items="categories"
                          item-title="name"
                          item-value="id"
                          @update:model-value="handleEditCategoryChange"
                          :error-messages="categoryErrorMessage"
                          label="دسته بندی"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>


                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="newProductData.productBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="newProductData.description"
                          textarea
                          label="توضیح محصول"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleFileUpload"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatus === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatus === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatus === 'error'">ناموفق</span>
                    </v-col>


                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewProduct">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </v-main>
    </v-app>


    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog v-model="deleteDialog" persistent max-width="400"
              style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <v-card>
        <v-card-title class="headline">حذف محصول</v-card-title>
        <v-card-text>آیا از حذف محصول "{{ productToDelete.title }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteProduct">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-locale-provider>


</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';
import * as persianHelpers from "persian-helpers";

export default {
  name: 'aassAA',
  data() {
    return {
      isUploading: false,
      uploadStatus: null,
      deleteDialog: false,
      productToDelete: null,
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',
      filterStatus: 'all',
      editDialog: false,
      newProductDialog: false,
      editedIndex: -1,
      categoryErrorMessage: '',
      parentProducts: [],
      categories: [],
      newProductData: {
        name: '',
        price: 0,
        availableCount: 0,
        category: 'انتخاب نشده',
        productParentId: 'انتخاب نشده',
        imgNewProductUrl: '',
        description: '',
        productBranch: '',
        imgNewProductFileId: ''
      },
      editedProduct: {
        id: 1,
        title: '',
        pId: 1,
        price: 0,
        inStockNumber: 0,
        productBranch: '',
        description: '',
        active: true,
        category: '',
        img: '',
        imgNewProductUrl: '',
        imgNewProductFileId: ''
      },
      branchTypes: [
        {
          value: 'COFFEE_SHOP',
          title: 'کافی شاپ'
        },
        {
          value: 'RESTAURANT',
          title: 'رستوران'
        }
      ],
      headers: [

        {
          title: 'تصویر',
          align: 'start',
          sortable: false,
          value: 'img',
        },
        {
          title: 'نام',
          align: 'start',
          sortable: true,
          value: 'title',
        }, {
          title: 'وضعیت',
          align: 'start',
          sortable: true,
          value: 'active',
        },
        {
          title: 'قیمت',
          align: 'start',
          sortable: true,
          value: 'price',
        },
        {
          title: 'موجودی',
          align: 'start',
          sortable: true,
          value: 'inStockNumber',
        },
        {
          title: 'بخش',
          align: 'start',
          sortable: true,
          value: 'productBranch',
        },{
          title: 'دسته بندی',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },

      ],
      products: [
        {
          id: 1,
          pId: 1,
          inStockNumber: 6,
          title: 'قهوه',
          categoryInfo: '',
          price: 20000,
          img: 'http://192.168.222.240:1020/fileUpload/files/a3aa86ce-76c8-456e-8f1f-9269fe740c89-product.jpg',
          active: true,
        }
      ],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils,
  },
  computed: {
    filteredProducts() {
      let filtered = this.products
      if (this.filterStatus === 'active') {
        filtered = filtered.filter((product) => product.active)
      } else if (this.filterStatus === 'inactive') {
        filtered = filtered.filter((product) => !product.active)
      }
      return filtered
    },
  },
  methods: {
    getBranch(item) {
      if (item.productBranch) {
        return item.productBranch.title;
      } else {
        return '';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case true:
          return '#D4EDDA';
        case false:
          return '#F8D7DA';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case true:
          return '#155724';
        case false:
          return '#721C24';
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return 'فعال';
        case false:
          return 'غیرفعال';
      }
    },
    convertNumberToFarsi(price, sep) {
      return persianHelpers.convertDigits(price, {separator: sep});
    },
    getCategoryNameById(categoryId) {
      if (!Array.isArray(this.categories) || this.categories.length === 0) {
        return "";
      }
      const category = this.categories.find(cat => cat.id === categoryId);
      if (category && category.name) {
        return category.name;
      } else {
        return "";
      }
    },
    toggleActive(item) {
      let beforaState = item.active;
      const changeActiveProduct = {
        id: item.id,
        name: item.title,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveProduct);
      ApiService.post("/product/changeActive", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedProducts = this.products.map(product => {
          if (product.id === item.id) {
            return {...product, active: !beforaState};
          }
          return product;
        });

        this.products = updatedProducts;

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    openDeleteDialog(product) {
      this.productToDelete = product;
      this.deleteDialog = true;
    },
    confirmDeleteProduct() {
      const deleteProduct = {
        id: this.productToDelete.id,
        title: this.productToDelete.title,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteProduct);

      console.log(requestBody)
      ApiService.post("/product/delete", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.deleteProduct(this.productToDelete);
        this.deleteDialog = false;
        console.log(response)
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })


    },
    deleteProduct(product) {
      const index = this.products.findIndex(p => p.id === product.id);
      if (index !== -1) {
        this.products.splice(index, 1);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.uploadFile(file);
      // this.editedProduct.image = [file];
    },
    handleEditFileUpload(event) {
      const file = event.target.files[0];
      this.uploadEditFile(file);
      this.uploadStatus = null;
      // this.editedProduct.image = [file];
    },
    getImgUrl(img) {
      return ApiService.getBaseUrl() + "/" + img;
    },
    uploadFile(file) {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "product");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        this.newProductData.imgNewProductUrl = res.imgUpUrl
        this.newProductData.imgNewProductFileId = res.imgFileId
        this.uploadStatus = 'success';
        this.isUploading = false;
        setTimeout(() => {
          this.uploadStatus = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatus = 'error';
        this.isUploading = false;
      });

    },
    uploadEditFile(file) {
      this.isUploading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "product");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        console.log(res)
        this.editedProduct.imgNewProductUrl = res.imgUpUrl
        this.editedProduct.imgNewProductFileId = res.imgFileId
        this.uploadStatus = 'success';
        this.isUploading = false;
        setTimeout(() => {
          this.uploadStatus = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatus = 'error';
        this.isUploading = false;
      });

    },
    handleCategoryChange(newValue) {
      if (newValue === 'انتخاب نشده') {
        this.categoryErrorMessage = 'لطفا دسته‌بندی را انتخاب کنید';
      } else {
        this.categoryErrorMessage = '';
      }
    },
    handleEditCategoryChange(newValue) {
      this.editedProduct.category = newValue
      if (newValue === 'انتخاب نشده') {
        this.categoryErrorMessage = 'لطفا دسته‌بندی را انتخاب کنید';
      } else {
        this.categoryErrorMessage = '';
      }
    },
    updateImage(file) {
      console.log("updateImage : ", file)
      this.editedProduct.image = file;
    },
    openEditProductDialog(product) {
      console.log("openEditProductDialog : ", product)
      this.editedIndex = this.products.indexOf(product)
      this.editedProduct = Object.assign({}, product)
      console.log("this.editedProduct : ", this.editedProduct)
      if (product.categoryInfo && product.categoryInfo.id) {
        this.editedProduct.category = product.categoryInfo.id
      }

      this.editDialog = true

      ApiService.get("/product/getProductCategoryList", this.config).then(response => {
        console.log(response)
        this.parentProducts = [{id: 0, name: 'انتخاب نشده'}, ...response.productList];
        this.categories = [{id: 0, name: 'انتخاب نشده'}, ...response.categoryList];
      }).catch(error => {
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
      console.log("this.editedProduct530 : ", this.editedProduct)
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedProduct = Object.assign({}, this.defaultProduct)
        this.editedIndex = -1
      })
    },
    closeNewProductDialog() {
      this.newProductDialog = false

    },
    openNewProductDialog() {

      console.log("config : ", this.config)
      ApiService.get("/product/getProductCategoryList", this.config).then(response => {
        console.log(response)
        this.parentProducts = [{id: 0, name: 'انتخاب نشده'}, ...response.productList];
        this.categories = [{id: 0, name: 'انتخاب نشده'}, ...response.categoryList];
      }).catch(error => {
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })

      this.newProductDialog = true
    },
    saveEdit() {
      console.log("saveEdit : ", this.editedProduct)
      let editedProductInfo = this.editedProduct;

      // this.closeEdit()

      if (!this.editedProduct.productBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      console.log("this.newProductData : ", editedProductInfo)

      console.log("this.editedProduct.title : ", this.editedProduct.title)
      console.log("this.editedProduct.price : ", this.editedProduct.price)
      let categoryId = '';
      if (editedProductInfo.category.id) {
        categoryId = editedProductInfo.category.id;
      } else {
        categoryId = editedProductInfo.category
      }


      const newEditProductData = {
        id: editedProductInfo.id,
        title: editedProductInfo.title,
        price: editedProductInfo.price,
        inStockNumber: editedProductInfo.inStockNumber,
        // productParentId: this.editedProduct.productParentId.id,
        categoryId: categoryId,
        imgFileId: editedProductInfo.imgNewProductFileId,
        description: editedProductInfo.description,
        productBranch: editedProductInfo.productBranch.value,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditProductData);
      console.log("requestBody edi : ", requestBody)
      console.log(requestBody)
      ApiService.post("/product/update", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewProductDialog();
        this.newProductDataValue();
        this.closeEdit()

        if (this.editedIndex > -1) {
          let editPro = this.editedProduct;
          if (this.editedProduct.imgNewProductUrl) {
            editPro.img = this.editedProduct.imgNewProductUrl;
          }
          editPro.categoryInfo = this.editedProduct.category;
          Object.assign(this.products[this.editedIndex], editPro)
        }

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })

    },
    async saveNewProduct() {
      console.log("this.newProductData : ", this.newProductData)
      if (this.newProductData.category === 'انتخاب نشده' || this.newProductData.category.id === 0) {
        this.categoryErrorMessage = 'لطفا دسته‌بندی را انتخاب کنید';
        this.$refs.uiUtils.showSnackErrorMessage("لطفا دسته‌بندی را انتخاب کنید");
        return;
      }
      if (this.newProductData.imgNewProductUrl === '') {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا عکس محصول را انتخاب کنید");
        return;
      }
      if (!this.newProductData.productBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      const newProductData = {
        title: this.newProductData.name,
        price: this.newProductData.price,
        inStockNumber: this.newProductData.availableCount,
        productParentId: this.newProductData.productParentId.id,
        categoryId: this.newProductData.category.id,
        imgFileId: this.newProductData.imgNewProductFileId,
        description: this.newProductData.description,
        productBranch: this.newProductData.productBranch.value,
      };
      // let newImgUrl = this.newProductData.imgNewProductUrl;
      // let newCategoryInfo = this.newProductData.category;

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newProductData);
      console.log(requestBody)
      ApiService.post("/product/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewProductDialog();
        this.newProductDataValue();

        this.products.unshift(response.data)

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })

    },

    newProductDataValue() {
      this.newProductData = {
        name: '',
        price: 0,
        availableCount: 0,
        category: 'انتخاب نشده',
        productParentId: 'انتخاب نشده'
      };
    },
    getProductListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/product/getAllProducts", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.products = response.productList;
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(error)
      })
    }
  },
  mounted() {
    this.getProductListFromServer();
  },

}
</script>
<style>
.v-btn-toggle > .v-btn.active {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
}

.custom-dropdown-font {
  .v-list__item__title {
    font-family: 'fontBody', sans-serif !important;
    font-size: 18px !important;
  }
}
</style>
