<template>
  <v-locale-provider rtl>

  </v-locale-provider>
</template>

<script>
export default {
  name: 'AdminMoney',
  data() {
    return {}
  }
}
</script>