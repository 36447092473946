import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import {Notify, Quasar} from 'quasar'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'


// import VueCropper from 'vue-cropperjs';
// import 'cropperjs/dist/cropper.css';

import {VueReCaptcha} from 'vue-recaptcha-v3';

import {loadFonts} from './plugins/webfontloader'
import Vue from "@vue/cli-plugin-eslint/eslintOptions";
// import VueSocketIOExt from 'vue-socket.io-extended';
// import io from 'socket.io-client';
//
// const socket = io('http://localhost:8083');

Vue.config.lang = 'fa'
Vue.config.rtl = true
loadFonts()


createApp(App)
    .component('DatePicker', Vue3PersianDatetimePicker)
    .use(router)
    .use(store)
    // .use(VueSocketIOExt, socket)
    .use(vuetify)
    .use(Quasar, {
        plugins: {
            Notify
        }
    }).use(VueReCaptcha, {
    siteKey: '6LeQks8pAAAAAN5wONogNLeIoTFcU0-CXReV8qA3', loaderOptions: {
        autoHideBadge: true,
    },
})
    .use(Quasar)
    // .component('vue-cropper', VueCropper)
    .mount('#app')
