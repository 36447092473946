import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/Admin.vue'

const routes = [{
    path: '/', name: 'home', component: HomeView
}, {
    path: '/test', name: 'test', component: () => import(/* webpackChunkName: "test" */ '../views/TestItem.vue')
}, {
    path: '/t2', name: 'ssDate', component: () => import(/* webpackChunkName: "test" */ '../views/Test2.vue')
}, {
    path: '/t', name: 'test', component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
}, {
    path: '/login', name: 'login', component: () => import(/* webpackChunkName: "test" */ '../views/Login.vue')
}, {
    path: '/waiter', name: 'waiter', component: () => import(/* webpackChunkName: "test" */ '../views/Waiter.vue')
}]

const router = createRouter({
    history: createWebHashHistory(), routes
})

export default router
