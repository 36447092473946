<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <q-btn dense outlined small color="success" @click="openNewTransactionCategoryDialog"
                         style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;">
                    <v-icon small>mdi-pen-plus</v-icon>
                    افزودن دسته‌بندی جدید
                  </q-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="transactionCategories" :search="search"
                              page-text="{0}-{1} از {2}" items-per-page-text="تعداد در صفحه : "
                              :items-per-page-options="probCountInpPage" mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ getTransactionCategoryTypeText(item.type) }}</td>
                      <td>{{ item.description }}</td>
                      <td>
                        <v-btn-group v-if="$q.screen.lt.md">
                          <q-btn small color="primary" @click="openEditTransactionCategoryDialog(item)">
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn @click="deleteTransactionCategory(item)" small color="error">
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                        </v-btn-group>
                        <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                               @click="openEditTransactionCategoryDialog(item)">
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                               @click="deleteTransactionCategory(item)">
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- Dialog for editing transaction category -->
          <v-dialog v-model="editDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش دسته‌بندی</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedTransactionCategory.name" label="نام دسته‌بندی"></v-text-field>
                    </v-col>
                    <v-col v-if="false" cols="12">
                      <v-text-field v-model="editedTransactionCategory.code" label="کد دسته‌بندی"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-if="false"
                                v-model="newTransactionCategoryData.type"
                                :items="transactionCategoryTypes"
                                item-title="text"
                                item-value="value"
                                label="نوع دسته‌بندی"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedTransactionCategory.description" label="توضیحات"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog for creating new transaction category -->
          <v-dialog v-model="newTransactionCategoryDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewTransactionCategoryDialog">mdi-close</v-icon>
                <span class="headline">افزودن دسته‌بندی جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newTransactionCategoryData.name" label="نام دسته‌بندی"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newTransactionCategoryData.code" label="کد دسته‌بندی"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="newTransactionCategoryData.type"
                          :items="transactionCategoryTypes"
                          item-title="text"
                          item-value="value"
                          label="نوع دسته‌بندی"
                      ></v-select>

                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newTransactionCategoryData.description" label="توضیحات"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewTransactionCategory">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>
<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'TransactionCategoryManagement',
  data() {
    return {
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      editDialog: false,
      newTransactionCategoryDialog: false,
      editedIndex: -1,
      newTransactionCategoryData: {name: '', code: '', type: '', description: ''},
      editedTransactionCategory: {id: null, name: '', code: '', type: '', description: ''},
      headers: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'کد', align: 'start', sortable: true, value: 'code'},
        {title: 'نوع دسته‌بندی', align: 'start', sortable: true, value: 'type'},
        {title: 'توضیحات', align: 'start', sortable: true, value: 'description'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      transactionCategoryTypes: [
        {value: 'INCOME', text: 'درآمد'},
        {value: 'EXPENSE', text: 'هزینه'}
      ],
      transactionCategories: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    getTransactionCategoryTypeText(type) {
      const categoryType = this.transactionCategoryTypes.find(ct => ct.value === type);
      return categoryType ? categoryType.text : '';
    },
    openEditTransactionCategoryDialog(transactionCategory) {
      this.editedIndex = this.transactionCategories.indexOf(transactionCategory);
      this.editedTransactionCategory = Object.assign({}, transactionCategory);
      this.editDialog = true;
    },
    closeEdit() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedTransactionCategory = Object.assign({}, this.defaultTransactionCategory);
        this.editedIndex = -1;
      });
    },
    closeNewTransactionCategoryDialog() {
      this.newTransactionCategoryDialog = false;
    },
    openNewTransactionCategoryDialog() {
      this.newTransactionCategoryDialog = true;
    },
    saveEdit() {
      const editedTransactionCategoryData = this.editedTransactionCategory;
      const requestBody = JSON.stringify(editedTransactionCategoryData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put("/transactionCategory/update", requestBody, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit();
        if (this.editedIndex > -1) {
          Object.assign(this.transactionCategories[this.editedIndex], this.editedTransactionCategory);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    saveNewTransactionCategory() {
      const newTransactionCategoryData = this.newTransactionCategoryData;
      const requestBody = JSON.stringify(newTransactionCategoryData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post("/transactionCategory/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewTransactionCategoryDialog();
        this.transactionCategories.unshift({
          ...newTransactionCategoryData,
          id: response.data.id
        });
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    deleteTransactionCategory(transactionCategory) {
      const deleteTransactionCategoryId = transactionCategory.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/transactionCategory/delete?transactionCategoryId=${deleteTransactionCategoryId}`, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        const index = this.transactionCategories.findIndex(tc => tc.id === transactionCategory.id);
        if (index !== -1) {
          this.transactionCategories.splice(index, 1);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    getTransactionCategoryListFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get("/transactionCategory/getAllTransactionCategories", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.transactionCategories = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(error);
      });
    }
  },
  mounted() {
    this.getTransactionCategoryListFromServer();
  }
}
</script>
