<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4">
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterType" style="margin-top: 10px;border-radius: 0px">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterType === 'all' }"
                        style="background: #343941;color: #9da7b6"
                    >همه
                    </v-btn>
                    <v-btn
                        value="INCOME"
                        :class="{ 'active': filterType === 'INCOME' }"
                        style="background: #343941;color: #9da7b6"
                    >درآمد
                    </v-btn>
                    <v-btn
                        value="EXPENSE"
                        :class="{ 'active': filterType === 'EXPENSE' }"
                        style="background: #343941;color: #9da7b6"
                    >هزینه
                    </v-btn>
                  </v-btn-toggle>
                  <v-btn color="primary" @click="openAddTransactionDialog">افزودن تراکنش</v-btn>
                </v-card-title>
                <v-data-table-server
                    :headers="headers"
                    :items="filteredTransactions"
                    :search="search"
                    :items-length="totalTransactionItems.toString()"
                    :page="currentPage"
                    @update:options="loadTransactions"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="countInPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>
  <span
      :style="{
      backgroundColor: getTypeColor(item.transactionType),
      color: getTypeTextColor(item.transactionType),
      padding: '4px 8px',
      borderRadius: '4px'
    }"
  >
    {{ getTypeText(item.transactionType) }}
  </span>
                      </td>
                      <td>{{ item.inputType }}</td>
                      <td>{{ convertNumberToFarsi(item.amount, true) }} تومان</td>
                      <td>{{ getCategoryName(item.categoryId) }}</td>
                      <td>{{ item.typeId ? item.typeId : '-' }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ formatPersianDate(item.fromDate) }}</td>
                      <td>
                        <!--                        <v-btn color="primary" @click="openEditTransactionDialog(item)">ویرایش</v-btn>-->
                        <v-btn color="error" @click="deleteTransaction(item.id)">حذف</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table-server>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog v-model="addTransactionDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;">
      <v-card>
        <v-card-title>افزودن تراکنش</v-card-title>
        <v-card-text>
          <v-form ref="addTransactionForm">
            <v-select
                v-model="newTransaction.transactionType"
                label="نوع تراکنش"
                :items="transactionTypes"
                item-title="title"
                item-value="value"
                required
                @change="onTransactionTypeChange"
            ></v-select>
            <v-select
                v-model="newTransaction.inputType"
                label="روش پرداخت"
                :items="inputTypes"
                required
            ></v-select>
            <v-text-field
                v-model="newTransaction.amount"
                label="مبلغ"
                type="number"
                required
            ></v-text-field>
            <v-select
                v-model="newTransaction.categoryId"
                label="دسته‌بندی تراکنش"
                :items="filterCategories"
                item-title="name"
                item-value="id"
                required
            ></v-select>
            <v-text-field
                v-model="newTransaction.orderPaymentId"
                label="شناسه پرداخت سفارش"
                type="number"
            ></v-text-field>
            <v-textarea
                v-model="newTransaction.description"
                label="توضیحات"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addTransaction">افزودن</v-btn>
          <v-btn color="secondary" @click="closeAddTransactionDialog">انصراف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editTransactionDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;">
      <v-card>
        <v-card-title>ویرایش تراکنش</v-card-title>
        <v-card-text>
          <v-form ref="editTransactionForm">
            <v-select
                v-model="editedTransaction.transactionType"
                label="نوع تراکنش"
                :items="transactionTypes"
                item-title="title"
                item-value="value"
                required
                @change="onTransactionTypeChange"
            ></v-select>
            <v-select
                v-model="editedTransaction.inputType"
                label="روش پرداخت"
                :items="inputTypes"
                required
            ></v-select>
            <v-text-field
                v-model="editedTransaction.amount"
                label="مبلغ"
                type="number"
                required
            ></v-text-field>
            <v-select
                v-model="editedTransaction.categoryId"
                label="دسته‌بندی تراکنش"
                :items="filterCategories"
                item-title="name"
                item-value="id"
                required
            ></v-select>
            <v-text-field
                v-model="editedTransaction.orderPaymentId"
                label="شناسه پرداخت سفارش"
                type="number"
            ></v-text-field>
            <v-textarea
                v-model="editedTransaction.description"
                label="توضیحات"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateTransaction">ذخیره</v-btn>
          <v-btn color="secondary" @click="closeEditTransactionDialog">انصراف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-locale-provider>
</template>
<script>
import UiUtils from "@/components/util/UiUtils.vue";
import ApiService from "@/services/ApiService";
import * as persianHelpers from "persian-helpers";
import {useQuasar} from "quasar";
import jalaliMoment from "jalali-moment";

export default {
  name: 'TransactionManagement',
  components: {UiUtils},
  data() {
    return {
      totalTransactionItems: 0,
      search: '',
      currentPage: 1,
      countInPage: [10, 25, 50],
      filterType: 'all',
      headers: [
        {title: 'شناسه', align: 'start', value: 'id'},
        {title: 'نوع تراکنش', align: 'start', value: 'transactionType'},
        {title: 'روش پرداخت', align: 'start', value: 'inputType'},
        {title: 'مبلغ', align: 'start', value: 'amount'},
        {title: 'دسته‌بندی', align: 'start', value: 'categoryId'},
        {title: 'منبع', align: 'start', value: 'orderPaymentId'},
        {title: 'توضیحات', align: 'start', value: 'description'},
        {title: 'تاریخ', align: 'start', value: 'fromDate'},
        {title: 'عملیات', align: 'start', sortable: false, value: 'actions'}
      ],
      transactions: [],
      transactionTypes: [
        {title: 'درآمد', value: 'INCOME'},
        {title: 'هزینه', value: 'EXPENSE'}
      ],
      inputTypes: ['نقدی', 'کارت خوان', 'انتقال بانکی'],
      categories: [],
      addTransactionDialog: false,
      editTransactionDialog: false,
      newTransaction: {
        transactionType: '',
        inputType: '',
        amount: null,
        categoryId: null,
        orderPaymentId: null,
        description: ''
      },
      editedTransaction: {},
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
      q: useQuasar(),
    }
  },
  computed: {
    filteredTransactions() {
      if (this.filterType === 'all') {
        return this.transactions;
      } else {
        return this.transactions.filter(transaction => transaction.transactionType === this.filterType);
      }
    },
    filterCategories() {
      if (this.newTransaction.transactionType === 'INCOME') {
        return this.categories.filter(category => category.type === 'INCOME');
      } else if (this.newTransaction.transactionType === 'EXPENSE') {
        return this.categories.filter(category => category.type === 'EXPENSE');
      } else {
        return [];
      }
    }
  },
  methods: {
    formatPersianDate(dateString) {
      const miladi = new Date(dateString)
      const shamsi = jalaliMoment(miladi).format('jYYYY/jM/jD HH:mm:ss')
      return shamsi;
    },
    loadTransactions(opt) {
      const size = opt.itemsPerPage;
      const page = opt.page - 1;

      // this.$refs.uiUtils.showIsLoading();

      ApiService.get(`/accountTransaction/getAllAccountTransactions?page=${page}&size=${size}`, this.config)
          .then(response => {
            this.transactions = response.transactionMdls;
            this.totalTransactionItems = response.allItemsCount;
            this.$refs.uiUtils.dismissIsLoading();
          })
          .catch(error => {
            console.error(error);
            this.$refs.uiUtils.dismissIsLoading();
            this.q.notify({
              type: 'negative',
              message: 'خطا در دریافت لیست تراکنش‌ها',
              position: 'bottom',
              progress: true
            });
          });
    },
    convertNumberToFarsi(price, sep) {
      return persianHelpers.convertDigits(price, {separator: sep});
    },
    getTypeText(type) {
      switch (type) {
        case 'INCOME':
          return 'درآمد';
        case 'EXPENSE':
          return 'هزینه';
        default:
          return type;
      }
    },
    getTypeColor(type) {
      switch (type) {
        case 'INCOME':
          return '#D4EDDA';
        case 'EXPENSE':
          return '#F8D7DA';
        default:
          return '#FFFFFF';
      }
    },
    getTypeTextColor(type) {
      switch (type) {
        case 'INCOME':
          return '#155724';
        case 'EXPENSE':
          return '#721C24';
        default:
          return '#000000';
      }
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(c => c.id === categoryId);
      return category ? category.name : '-';
    },
    onTransactionTypeChange() {
      this.newTransaction.categoryId = null;
    },
    openAddTransactionDialog() {
      this.addTransactionDialog = true;
    },
    closeAddTransactionDialog() {
      this.addTransactionDialog = false;
      this.newTransaction = {
        transactionType: '',
        inputType: '',
        amount: null,
        categoryId: null,
        orderPaymentId: null,
        description: ''
      };
      this.$refs.addTransactionForm.reset();
    },
    addTransaction() {
      if (this.$refs.addTransactionForm.validate()) {
        const requestBody = JSON.stringify(this.newTransaction);
        this.$refs.uiUtils.showIsLoading();

        ApiService.post('/accountTransaction/create', requestBody, this.config)
            .then(() => {
              this.closeAddTransactionDialog();
              this.loadTransactions({itemsPerPage: 10, page: 1});
              this.$refs.uiUtils.showSuccessDialog();
            })
            .catch(error => {
              console.error(error);
              this.$refs.uiUtils.showError('خطا در ایجاد تراکنش');
            });
      }
    },
    openEditTransactionDialog(item) {
      this.editedTransaction = {...item};
      this.editTransactionDialog = true;
    },
    closeEditTransactionDialog() {
      this.editTransactionDialog = false;
      this.editedTransaction = {};
      this.$refs.editTransactionForm.reset();
    },
    updateTransaction() {
      if (this.$refs.editTransactionForm.validate()) {
        const requestBody = JSON.stringify(this.editedTransaction);
        this.$refs.uiUtils.showIsLoading();

        ApiService.put('/accountTransaction/update', requestBody, this.config)
            .then(() => {
              this.closeEditTransactionDialog();
              this.loadTransactions({itemsPerPage: 10, page: this.currentPage});
              this.$refs.uiUtils.showSuccessDialog();
            })
            .catch(error => {
              console.error(error);
              this.$refs.uiUtils.showError('خطا در ویرایش تراکنش');
            });
      }
    },
    deleteTransaction(id) {

      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/accountTransaction/delete?accountTransactionId=${id}`, this.config)
          .then(() => {
            this.loadTransactions({itemsPerPage: 10, page: this.currentPage});
            this.$refs.uiUtils.showSuccessDialog();
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading()
            console.error(error);
            this.$refs.uiUtils.showError(error.message);
          });

    },
    loadTransactionCategories() {
      ApiService.get('/transactionCategory/getAllTransactionCategories', this.config)
          .then(response => {
            this.categories = response;
          })
          .catch(error => {
            console.error(error);
            this.q.notify({
              type: 'negative',
              message: 'خطا در دریافت لیست دسته‌بندی‌ها',
              position: 'bottom',
              progress: true
            });
          });
    }
  },
  mounted() {
    this.loadTransactions({itemsPerPage: 10, page: 1});
    this.loadTransactionCategories();
  }
}
</script>
<style scoped>
.v-btn-toggle > .v-btn.active {
  background-color: #ffffff !important;
  border-radius: 5px;
  color: #000000 !important;
}
</style>
