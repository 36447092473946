<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-select
                      v-model="selectedProduct"
                      :items="products"
                      item-title="name"
                      item-value="id"
                      label="محصول"
                      @change="resetQuantity"
                  ></v-select>
                  <v-text-field
                      v-model="quantity"
                      label="تعداد"
                      type="number"
                      min="1"
                      style="max-width: 200px;"
                  ></v-text-field>
                  <v-btn color="primary" @click="calculateMaterialsNeeded">
                    محاسبه
                  </v-btn>
                </v-card-title>

                <v-data-table :headers="headers" :items="materialsNeeded" :search="search"
                              page-text="{0}-{1} از {2}"
                              items-per-page-text="تعداد در صفحه : " :items-per-page-options="probCountInpPage"
                              mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.material.name }}</td>
                      <td>{{ item.quantityNeeded }}</td>
                      <td>{{ item.material.unitOfMeasure.name }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'ProductionQuantities',
  data() {
    return {
      products: [],
      selectedProduct: null,
      quantity: 1,
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      headers: [
        {title: 'نام ماده اولیه', align: 'start', sortable: true, value: 'material.name'},
        {title: 'مقدار مورد نیاز', align: 'start', sortable: true, value: 'quantityNeeded'},
        {title: 'واحد', align: 'start', sortable: true, value: 'material.unitOfMeasure.name'}
      ],
      materialsNeeded: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    calculateMaterialsNeeded() {
      if (!this.selectedProduct || this.quantity <= 0) return;

      const dto = {
        productId: this.selectedProduct,
        requiredQuantity: this.quantity
      };
      this.$refs.uiUtils.showIsLoading();

      ApiService.post('formula/calculateMaterialsNeeded', dto, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.materialsNeeded = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    resetQuantity() {
      this.quantity = 1;
      this.materialsNeeded = [];
    },
    getProductsFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/product/getProductsSelect", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.products = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    },
  },
  mounted() {
    this.getProductsFromServer();
  }
}
</script>
