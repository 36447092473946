<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <q-btn dense outlined small color="success" @click="openNewAccountDialog"
                         style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;">
                    <v-icon small>mdi-pen-plus</v-icon>
                    افزودن حساب جدید
                  </q-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="accounts" :search="search" page-text="{0}-{1} از {2}"
                              items-per-page-text="تعداد در صفحه : " :items-per-page-options="probCountInpPage"
                              mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.active ? 'فعال' : 'غیرفعال' }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.balance }}</td>
                      <td>{{ item.debit }}</td>
                      <td>{{ item.credit }}</td>
                      <td>
                        <v-btn-group v-if="$q.screen.lt.md">
                          <q-btn small color="primary" @click="openEditAccountDialog(item)">
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn @click="deleteAccount(item)" small color="error">
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn @click="setActiveAccount(item)" small color="teal">
                            <v-icon small>mdi-star</v-icon>
                            تنظیم به عنوان حساب فعال
                          </q-btn>
                        </v-btn-group>
                        <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                               @click="openEditAccountDialog(item)">
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2" @click="deleteAccount(item)">
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" small color="teal" class="mr-2" @click="setActiveAccount(item)">
                          <v-icon small>mdi-star</v-icon>
                          تنظیم به عنوان حساب فعال
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- Dialog for editing account -->
          <v-dialog v-model="editDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش حساب</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccount.name" label="نام حساب"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccount.code" label="کد حساب"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="editedAccount.description" label="توضیحات"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog for creating new account -->
          <v-dialog v-model="newAccountDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewAccountDialog">mdi-close</v-icon>
                <span class="headline">افزودن حساب جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newAccountData.name" label="نام حساب"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newAccountData.code" label="کد حساب"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newAccountData.description" label="توضیحات"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewAccount">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>
<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'AccountManagement',
  data() {
    return {
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      editDialog: false,
      newAccountDialog: false,
      editedIndex: -1,
      newAccountData: {name: '', code: '', description: ''},
      editedAccount: {id: null, name: '', code: '', description: ''},
      headers: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'وضعیت', align: 'start', sortable: true, value: 'active'},
        {title: 'توضیحات', align: 'start', sortable: true, value: 'description'},
        {title: 'مانده', align: 'start', sortable: true, value: 'balance'},
        {title: 'بدهکار', align: 'start', sortable: true, value: 'debit'},
        {title: 'بستانکار', align: 'start', sortable: true, value: 'credit'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      accountTypes: ['درآمد', 'هزینه', 'دارایی', 'بدهی'],
      accounts: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    openEditAccountDialog(account) {
      this.editedIndex = this.accounts.indexOf(account);
      this.editedAccount = Object.assign({}, account);
      this.editDialog = true;
    },
    closeEdit() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedAccount = Object.assign({}, this.defaultAccount);
        this.editedIndex = -1;
      });
    },
    closeNewAccountDialog() {
      this.newAccountDialog = false;
    },
    openNewAccountDialog() {
      this.newAccountDialog = true;
    },
    saveEdit() {
      const editedAccountData = {
        id: this.editedAccount.id,
        name: this.editedAccount.name,
        code: this.editedAccount.code,
        description: this.editedAccount.description
      };
      const requestBody = JSON.stringify(editedAccountData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put("/account/update", requestBody, this.config).then((response) => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit();
        console.log("/account/update : ", response)
        if (this.editedIndex > -1) {
          Object.assign(this.accounts[this.editedIndex], response.data);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    saveNewAccount() {
      const newAccountData = {
        name: this.newAccountData.name,
        code: this.newAccountData.code,
        description: this.newAccountData.description
      };
      const requestBody = JSON.stringify(newAccountData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post("/account/create", requestBody, this.config).then(response => {
        console.log("/account/create : ", response)
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewAccountDialog();
        this.accounts.unshift(response.data);
        this.newAccountData = {}
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    deleteAccount(account) {
      const deleteAccountId = account.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/account/delete?accountId=${deleteAccountId}`, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        const index = this.accounts.findIndex(a => a.id === account.id);
        if (index !== -1) {
          this.accounts.splice(index, 1);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    setActiveAccount(account) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.put(`/account/setActiveAccount?accountId=${account.id}`, null, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        this.accounts.forEach(a => a.active = (a.id === account.id));
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    getAccountListFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get("/account/getAllAccounts", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.accounts = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(error);
      });
    }
  },
  mounted() {
    this.getAccountListFromServer();
  }
}
</script>
