<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <q-btn dense outlined small color="success" @click="openNewStakeholderDialog"
                         style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;">
                    <v-icon small>mdi-pen-plus</v-icon>
                    افزودن ذینفع جدید
                  </q-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="stakeholders" :search="search" page-text="{0}-{1} از {2}"
                              items-per-page-text="تعداد در صفحه : " :items-per-page-options="probCountInpPage"
                              mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.sharePercentage }}</td>
                      <td>
                        <v-btn-group v-if="$q.screen.lt.md">
                          <q-btn small color="primary" @click="openEditStakeholderDialog(item)">
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn @click="deleteStakeholder(item)" small color="error">
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                        </v-btn-group>
                        <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                               @click="openEditStakeholderDialog(item)">
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                               @click="deleteStakeholder(item)">
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- Dialog for editing stakeholder -->
          <v-dialog v-model="editDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش ذینفع</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedStakeholder.name" label="نام ذینفع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedStakeholder.type" label="نوع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedStakeholder.sharePercentage" label="درصد سهم"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog for creating new stakeholder -->
          <v-dialog v-model="newStakeholderDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewStakeholderDialog">mdi-close</v-icon>
                <span class="headline">افزودن ذینفع جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newStakeholderData.name" label="نام ذینفع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newStakeholderData.type" label="نوع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newStakeholderData.sharePercentage" label="درصد سهم"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewStakeholder">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>
<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'StakeholderManagement',
  data() {
    return {
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      editDialog: false,
      newStakeholderDialog: false,
      editedIndex: -1,
      newStakeholderData: {name: '', type: '', sharePercentage: ''},
      editedStakeholder: {id: null, name: '', type: '', sharePercentage: ''},
      headers: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'نوع', align: 'start', sortable: true, value: 'type'},
        {title: 'درصد سهم', align: 'start', sortable: true, value: 'sharePercentage'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      stakeholders: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    openEditStakeholderDialog(stakeholder) {
      this.editedIndex = this.stakeholders.indexOf(stakeholder);
      this.editedStakeholder = Object.assign({}, stakeholder);
      this.editDialog = true;
    },
    closeEdit() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedStakeholder = Object.assign({}, this.defaultStakeholder);
        this.editedIndex = -1;
      });
    },
    closeNewStakeholderDialog() {
      this.newStakeholderDialog = false;
    },
    openNewStakeholderDialog() {
      this.newStakeholderDialog = true;
    },
    saveEdit() {
      const editedStakeholderData = {
        id: this.editedStakeholder.id,
        name: this.editedStakeholder.name,
        type: this.editedStakeholder.type,
        sharePercentage: this.editedStakeholder.sharePercentage
      };
      const requestBody = JSON.stringify(editedStakeholderData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put("/stakeholder/update", requestBody, this.config).then((response) => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit();
        console.log("/stakeholder/update : ", response)
        if (this.editedIndex > -1) {
          Object.assign(this.stakeholders[this.editedIndex], response.data);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    saveNewStakeholder() {
      const newStakeholderData = {
        name: this.newStakeholderData.name,
        type: this.newStakeholderData.type,
        sharePercentage: this.newStakeholderData.sharePercentage
      };
      const requestBody = JSON.stringify(newStakeholderData);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post("/stakeholder/create", requestBody, this.config).then(response => {
        console.log("/stakeholder/create : ", response)
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewStakeholderDialog();
        this.stakeholders.unshift(response.data);
        this.newStakeholderData = {}
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    deleteStakeholder(stakeholder) {
      const deleteStakeholderId = stakeholder.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/stakeholder/delete?stakeholderId=${deleteStakeholderId}`, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        const index = this.stakeholders.findIndex(a => a.id === stakeholder.id);
        if (index !== -1) {
          this.stakeholders.splice(index, 1);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      });
    },
    getStakeholderListFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get("/stakeholder/getAllStakeholders", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.stakeholders = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(error);
      });
    }
  },
  mounted() {
    this.getStakeholderListFromServer();
  }
}
</script>
