<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>
                    <q-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewCategoryDialog"
                        style="position: absolute; left: 0;"
                    >
                      <v-icon small>mdi-pen-plus</v-icon>
                      افزودن موزیک جدید
                    </q-btn>
                  </v-btn-toggle>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredCategories"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <v-img
                            style="border-radius: 50px;margin-right: -5px;"
                            :src="getImgUrl(item.image)"
                            height="50"
                            width="50"
                        ></v-img>
                      </td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.singer }}</td>

                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.active),
          color: getStatusTextColor(item.active),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.active) }}
      </span>
                      </td>
                      <td>{{ item.like }}</td>
                      <td>{{ item.dislike }}</td>


                      <td>
                        <q-btn-group v-if="$q.screen.lt.md">
                          <q-btn
                              small
                              color="primary"
                              @click="openEditCategoryDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn
                              small
                              :color="item.active ? 'grey' : 'success'"
                              @click="toggleActive(item)"
                          >
                            <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.active ? 'غیرفعال شود' : 'فعال شود' }}
                          </q-btn>
                        </q-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditCategoryDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            style="margin-right: 15px"
                            small
                            :color="item.active ? 'grey' : 'success'"
                            @click="toggleActive(item)"
                        >
                          <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.active ? 'غیرفعال شود' : 'فــعـال شــود' }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="editDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedMusic.title }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMusic.title"
                          label="عنوان موزیک"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMusic.singer"
                          label="خواننده"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMusic.like"
                          label="لایک"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMusic.dislike"
                          label="دیسلایک"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleEditFileUpload"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploadingImage"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatusImage === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatusImage === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatusImage === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatusImage === 'error'">ناموفق</span>
                    </v-col>


                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newDeskDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewMusicDialog">mdi-close</v-icon>
                <span class="headline">افزودن موزیک جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMusicData.title"
                          label="عنوان موزیک"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMusicData.singer"
                          label="خواننده"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMusicData.like"
                          label="لایک"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMusicData.dislike"
                          label="دیسلایک"
                          type="number"
                      ></v-text-field>
                    </v-col>


                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب عکس"
                          accept="image/*"
                          @change="handleFileUploadImage"
                          @update:model-value="updateImage"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploadingImage"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatusImage === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatusImage === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatusImage === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatusImage === 'error'">ناموفق</span>
                    </v-col>

                    <v-col cols="12">
                      <v-file-input
                          label="انتخاب موزیک"
                          accept="audio/*"
                          @change="handleFileUploadPath"
                          @update:model-value="updatePath"
                      ></v-file-input>

                      <v-progress-circular
                          v-if="isUploadingFile"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>

                      <v-icon
                          v-if="uploadStatusFile === 'success'"
                          color="success"
                          size="large"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span v-if="uploadStatusFile === 'success'">موفق</span>

                      <v-icon
                          v-if="uploadStatusFile === 'error'"
                          color="error"
                          size="large"
                      >
                        mdi-close-circle
                      </v-icon>
                      <span v-if="uploadStatusFile === 'error'">ناموفق</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewCategory">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="400"
        style="font-family: 'fontBody', sans-serif;font-size: 18px"
    >
      <v-card>
        <v-card-title class="headline">حذف موزیک</v-card-title>
        <v-card-text>آیا از حذف موزیک "{{ musicToDelete.title }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteCategory">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'musicManagement',
  data() {
    return {
      isUploadingImage: false,
      uploadStatusImage: null,
      isUploadingFile: false,
      uploadStatusFile: null,
      deleteDialog: false,
      musicToDelete: null,
      uploadEditImageMusicFileId: '',
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',
      filterStatus: 'all',
      editDialog: false,
      newDeskDialog: false,
      editedIndex: -1,
      newMusicData: {
        title: '',
        singer: '',
        like: 0,
        dislike: 0,
        pathFileId: '',
        pathFileUrl: '',
        imageFileId: '',
        imageFileUrl: '',
      },
      editedMusic: {
        id: 1,
        title: '',
        singer: '',
        like: 0,
        dislike: 0,
        pathFileId: '',
        pathFileUrl: '',
        imageFileId: '',
        imageFileUrl: '',
      },
      headers: [
        {
          title: 'تصویر',
          align: 'start',
          sortable: false,
          value: 'img',
        },
        {
          title: 'عنوان',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          title: 'خواننده',
          align: 'start',
          sortable: false,
          value: 'singer',
        }, {
          title: 'وضعیت',
          align: 'start',
          sortable: false,
          value: 'active',
        }, {
          title: 'لایک',
          align: 'start',
          sortable: true,
          value: 'like',
        }, {
          title: 'دیسلایک',
          align: 'start',
          sortable: true,
          value: 'dislike',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      musics: [
        {
          id: 1007,
          title: "موزیک 1",
          path: "fileUpload/files/6d2fb0da-cff5-4f46-a9c0-1da09c97f4ac-musicFile.mp3",
          image: "fileUpload/files/75cbb7b3-7b86-47cd-a47b-1c03d77b3328-musicImage.jpg",
          like: 1,
          dislike: 1,
          singer: "میلاد"
        }
      ],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils,
  },
  computed: {
    filteredCategories() {
      let filtered = this.musics
      if (this.filterStatus === 'active') {
        filtered = filtered.filter((category) => category.active)
      } else if (this.filterStatus === 'inactive') {
        filtered = filtered.filter((category) => !category.active)
      }
      return filtered
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case true:
          return '#D4EDDA';
        case false:
          return '#F8D7DA';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case true:
          return '#155724';
        case false:
          return '#721C24';
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return 'فعال';
        case false:
          return 'غیرفعال';
      }
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      return dateString
    },
    toggleActive(item) {
      let beforaState = item.active;
      const changeActiveCategory = {
        id: item.id,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveCategory);
      ApiService.post("/music/updateStatus", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedCategories = this.musics.map(category => {
          if (category.id === item.id) {
            return {...category, active: !beforaState};
          }
          return category;
        });
        this.musics = updatedCategories;

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    openDeleteDialog(category) {
      this.musicToDelete = category;
      this.deleteDialog = true;
    },
    confirmDeleteCategory() {
      const deleteCategory = {
        id: this.musicToDelete.id,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteCategory);

      console.log(requestBody)
      ApiService.post("/music/delete", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.deleteCategory(this.musicToDelete);
        this.deleteDialog = false;
        console.log(response)
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteCategory(category) {
      const index = this.musics.findIndex(c => c.id === category.id);
      if (index !== -1) {
        this.musics.splice(index, 1);
      }
    },
    handleFileUploadImage(event) {
      const file = event.target.files[0];
      this.uploadFileImage(file);
    },
    handleFileUploadPath(event) {
      const file = event.target.files[0];
      this.uploadFilePath(file);
    },
    handleEditFileUpload(event) {
      const file = event.target.files[0];
      this.uploadEditFile(file);
      this.uploadStatusImage = null;
    },
    getImgUrl(img) {
      return ApiService.getBaseUrl() + "/" + img;
    },
    uploadFileImage(file) {
      this.isUploadingImage = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "musicImage");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        this.newMusicData.imageFileUrl = res.imgUpUrl
        this.newMusicData.imageFileId = res.imgFileId
        this.uploadStatusImage = 'success';
        this.isUploadingImage = false;
        setTimeout(() => {
          this.uploadStatusImage = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatusImage = 'error';
        this.isUploadingImage = false;
      });
    },
    uploadFilePath(file) {
      this.isUploadingFile = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "musicFile");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        this.newMusicData.pathFileUrl = res.imgUpUrl
        this.newMusicData.pathFileId = res.imgFileId
        this.uploadStatusFile = 'success';
        this.isUploadingFile = false;
        setTimeout(() => {
          this.uploadStatusImage = null;
        }, 2000);
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatusFile = 'error';
        this.isUploadingFile = false;
      });
    },
    uploadEditFile(file) {
      this.isUploadingImage = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sector', "editMusic");

      let configUploadFile = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      ApiService.post("/fileUpload/create", formData, configUploadFile).then(res => {
        console.log("edit upload : ", res)
        this.editedMusic.imageFileUrl = res.imgUpUrl
        this.editedMusic.imageFileId = res.imgFileId
        this.uploadStatusImage = 'success';
        this.isUploadingImage = false;
        setTimeout(() => {
          this.uploadStatusImage = null;
        }, 2000);
        console.log("fileUpload this.editedMusic: ", this.editedMusic)
      }).catch(err => {
        console.log("errrrrrrrrrr : ", err)
        this.uploadStatusImage = 'error';
        this.isUploadingImage = false;
      });
    },
    updateImage(file) {
      console.log("updateImage : ", file)
      this.editedMusic.image = file;
    },
    updatePath(file) {
      console.log("updatePath : ", file)
      this.editedMusic.image = file;
    },
    openEditCategoryDialog(category) {
      console.log("openEditCategoryDialog : ", category)
      this.editedIndex = this.musics.indexOf(category)
      this.editedMusic = Object.assign({}, category)
      console.log("this.editedMusic : ", this.editedMusic)

      this.editDialog = true
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedMusic = Object.assign({}, this.defaultCategory)
        this.editedIndex = -1
      })
    },
    closeNewMusicDialog() {
      this.newDeskDialog = false
    },
    openNewCategoryDialog() {
      this.newDeskDialog = true
    },
    saveEdit() {
      console.log("saveEdit : ", this.editedMusic)
      let editedCategoryData = this.editedMusic;

      const newEditCategoryData = {
        id: editedCategoryData.id,
        title: editedCategoryData.title,
        singer: editedCategoryData.singer,
        like: editedCategoryData.like,
        dislike: editedCategoryData.dislike,
        imageFileId: editedCategoryData.imageFileId,
        pathFileId: ''
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditCategoryData);
      console.log(requestBody)
      ApiService.post("/music/update", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()


        Object.assign(this.musics[this.editedIndex], response.data)


      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    async saveNewCategory() {
      if (this.newMusicData.imageFileUrl === '') {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا عکس موزیک را انتخاب کنید");
        return;
      }

      const newMusicData = {
        title: this.newMusicData.title,
        singer: this.newMusicData.singer,
        like: this.newMusicData.like,
        dislike: this.newMusicData.dislike,
        pathFileId: this.newMusicData.pathFileId,
        imageFileId: this.newMusicData.imageFileId,
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newMusicData);
      console.log(requestBody)
      ApiService.post("/music/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewMusicDialog();
        this.newCategoryDataValue();

        this.musics.unshift(response.data)

        console.log("this.musics : ", this.musics)

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    newCategoryDataValue() {
      this.newMusicData = {
        title: '',
        description: '',
        imageFileUrl: '',
        imageFileId: ''
      };
    },
    getCategoryListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/music/getAllMusic", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.musics = response.musicList;
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(error)
      })
    }
  },
  mounted() {
    this.getCategoryListFromServer();
  },
}
</script>
