<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <q-btn dense outlined small color="success" @click="openNewFormulaDialog"
                         style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;">
                    <v-icon small>mdi-pen-plus</v-icon>
                    افزودن فرمول جدید
                  </q-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="formulas"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.description }}</td>
                      <!--                      <td>نام</td>-->
                      <td>{{ item.product.title }}</td>
                      <td>
                        <v-btn-group v-if="$q.screen.lt.md">
                          <q-btn
                              small
                              color="primary"
                              @click="openEditFormulaDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn
                              small
                              color="info"
                              @click="openFormulaItemList(item)"
                          >
                            <v-icon small>mdi-view-list</v-icon>
                            آیتم ها
                          </q-btn>
                        </v-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditFormulaDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="info"
                            class="mr-2"
                            @click="openFormulaItemList(item)"
                        >
                          <v-icon small>mdi-view-list</v-icon>
                          آیتم ها
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="editDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedFormula.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedFormula.name"
                          label="نام فرمول"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedFormula.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="editedFormula.productId"
                          :items="products"
                          item-title="name"
                          item-value="id"
                          label="محصول"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newFormulaDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewFormulaDialog">mdi-close</v-icon>
                <span class="headline">افزودن فرمول جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newFormulaData.name"
                          label="نام فرمول"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newFormulaData.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="newFormulaData.productId"
                          :items="products"
                          item-title="name"
                          item-value="id"
                          label="محصول"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewFormula">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="formulaItemDialog"
              max-width="800px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeFormulaItemDialog">mdi-close</v-icon>
                <span class="headline">لیست آیتم های فرمول </span>
                <span class="headline" style="font-weight: bold">{{ selectedFormula.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-data-table
                    v-if="selectedFormula.itemMdls"
                    :headers="itemHeaders"
                    :items="selectedFormula.itemMdls"
                    mobile-breakpoint="600"
                >
                  <template v-slot:top>
                    <v-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewItemDialog"
                        style="margin-bottom: 10px;"
                    >
                      <v-icon small>mdi-plus</v-icon>
                      افزودن آیتم جدید
                    </v-btn>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.material.name }}</td>
                      <td>{{ item.quantity +" " + item.unitName}}</td>
                      <td>
                        <v-btn
                            small
                            color="error"
                            @click="deleteItem(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newItemDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewItemDialog">mdi-close</v-icon>
                <span class="headline">افزودن آیتم جدید به </span>
                <span class="headline" style="font-weight: bold">{{ selectedFormula.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          v-model="newItemData.materialId"
                          :items="materials"
                          item-title="name"
                          item-value="id"
                          @update:model-value="getUnitOfMeasureSelectedMaterial"
                          label="ماده اولیه"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newItemData.quantity"
                          label="مقدار"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="uniSelected"
                          :items="uniList"
                          item-title="title"
                          item-value="value"
                          label="واحد اندازه گیری"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewItem">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="400"
        style="font-family: 'fontBody', sans-serif;font-size: 18px"
    >
      <v-card>
        <v-card-title class="headline">حذف فرمول</v-card-title>
        <v-card-text>آیا از حذف فرمول "{{ formulaToDelete.name }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteFormula">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>
<script>

import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'FormulaManagement',
  data() {
    return {
      deleteDialog: false,
      formulaToDelete: null,
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      editDialog: false,
      newFormulaDialog: false,
      formulaItemDialog: false,
      newItemDialog: false,
      selectedFormula: null,
      editedIndex: -1,
      newFormulaData: {name: '', description: '', productId: null},
      newItemData: {formulaId: null, materialId: null, quantity: null},
      editedFormula: {id: null, name: '', description: '', productId: null},
      headers: [{title: 'شناسه', align: 'start', sortable: true, value: 'id',}, {
        title: 'نام',
        align: 'start',
        sortable: true,
        value: 'name',
      }, {title: 'توضیحات', align: 'start', sortable: true, value: 'description',}, {
        title: 'محصول', align: 'start',
        sortable: true,
        value: 'product.name',
      },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      itemHeaders: [
        {
          title: 'شناسه',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          title: 'ماده اولیه',
          align: 'start',
          sortable: true,
          value: 'material.name',
        },
        {
          title: 'مقدار',
          align: 'start',
          sortable: true,
          value: 'quantity',
        },
        {
          title: 'عملیات',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],

      formulas: [],
      itemMdls: [],
      products: [],
      materials: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
      unitOfMeasure: null,
      uniList: [],
      uniSelected: null
    }
  },
  components: {
    UiUtils
  },
  methods: {
    getUnitOfMeasureSelectedMaterial() {
      this.uniList = [];
      this.uniSelected = null;

      this.$refs.uiUtils.showIsLoading()
      ApiService.get(`/material/getMaterialById?id=` + this.newItemData.materialId, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.unitOfMeasure = response.unitOfMeasure;
        this.getSelectUnitsItems()
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })

    },
    getSelectUnitsItems() {
      const unitOfMeasure = this.unitOfMeasure;
      const units = [];

      if (unitOfMeasure && unitOfMeasure.baseUnitId) {
        if (unitOfMeasure.subsetUnits && Array.isArray(unitOfMeasure.subsetUnits)) {
          unitOfMeasure.subsetUnits.forEach(unit => {
            if (unit && unit.id && unit.name) {
              units.push({
                value: unit.id,
                title: unit.name
              });
            }
          });
        }
      } else if (unitOfMeasure && unitOfMeasure.derivedUnits && Array.isArray(unitOfMeasure.derivedUnits)) {
        unitOfMeasure.derivedUnits.forEach(unit => {
          if (unit && unit.id && unit.name) {
            units.push({
              value: unit.id,
              title: unit.name
            });
          }
        });
      }
      this.uniList = units;
      // return units;
    },
    openDeleteDialog(formula) {
      this.formulaToDelete = formula;
      this.deleteDialog = true;
    },
    confirmDeleteFormula() {
      const deleteFormulaId = this
          .formulaToDelete.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/formula/delete?formulaId=${deleteFormulaId}`, this.config).then(response => {
        console.log(response)
        this.$refs.uiUtils.dismissIsLoading();
        this.deleteFormula(this.formulaToDelete);
        this.deleteDialog = false;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteFormula(formula) {
      const index = this.formulas.findIndex(f => f.id === formula.id);
      if (index !== -1) {
        this.formulas.splice(index, 1);
      }
    },
    openEditFormulaDialog(formula) {
      this.editedIndex = this.formulas.indexOf(formula)
      this.editedFormula = Object.assign({}, formula)
      this.editDialog = true
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedFormula = Object.assign({}, this.defaultFormula)
        this.editedIndex = -1
      })
    },
    closeNewFormulaDialog() {
      this.newFormulaDialog = false
    },
    openNewFormulaDialog() {
      this.newFormulaDialog = true
    },
    openFormulaItemList(formula) {
      this.selectedFormula = formula;
      if (!this.selectedFormula.itemMdls) {
        this.selectedFormula.itemMdls = [];
      }
      this.formulaItemDialog = true;
    },
    closeFormulaItemDialog() {
      this.formulaItemDialog = false;
    },
    openNewItemDialog() {
      this.newItemData.formulaId = this.selectedFormula.id;
      this.newItemDialog = true;
    },
    closeNewItemDialog() {
      this.newItemDialog = false;
    },
    saveEdit() {
      let editedFormulaData = this.editedFormula;
      const newEditFormulaData = {
        id: editedFormulaData.id,
        name: editedFormulaData.name,
        description: editedFormulaData.description,
        productId: editedFormulaData.productId
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditFormulaData);

      ApiService.put("/formula/update", requestBody, this.config).then(response => {
        console.log(response)
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()

        if (this.editedIndex > -1) {
          const updatedFormula = {
            ...this.formulas[this.editedIndex],
            ...response.data
          };
          this.formulas.splice(this.editedIndex, 1, updatedFormula);
        }

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    saveNewFormula() {
      const newFormulaData = {
        name: this.newFormulaData.name,
        description: this.newFormulaData.description,
        productId: this.newFormulaData.productId
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newFormulaData);

      ApiService.post("/formula/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewFormulaDialog();
        this.newFormulaDataValue();

        this.formulas.unshift({
          ...response.data
        })

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    saveNewItem() {
      if (!this.uniSelected) {
        this.$refs.uiUtils.showSnackErrorMessage("واحد اندازه گیری انتخاب کنید");
        return;
      }

      const newItemData = {
        formulaId: this.newItemData.formulaId,
        materialId: this.newItemData.materialId,
        quantity: this.newItemData.quantity,
        unitOfMeasureId: this.uniSelected
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newItemData);

      ApiService.post("/formula/create/item", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewItemDialog();
        this.newItemDataValue();

        this.selectedFormula.itemMdls.unshift({
          ...response.data
        })

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    newFormulaDataValue() {
      this.newFormulaData = {
        name: '',
        description: '',
        productId: null
      };
    },
    newItemDataValue() {
      this.newItemData = {
        formulaId: null,
        materialId: null,
        quantity: null
      };
    },
    deleteItem(item) {
      const deleteItemId = item.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/formula/delete/item?formulaItemId=${deleteItemId}`, this.config).then(response => {
        console.log(response)
        this.$refs.uiUtils.dismissIsLoading();
        const index = this.selectedFormula.itemMdls.findIndex(i => i.id === item.id);
        if (index !== -1) {
          this.selectedFormula.itemMdls.splice(index, 1);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    getFormulaListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/formula/getAllFormula", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.formulas = response.formulaList;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    },
    getProductsFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/product/getProductsSelect", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.products = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    },
    getMaterialsFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/material/getMaterialsSelect", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.materials = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    }
  },
  mounted() {
    this.getFormulaListFromServer();
    this.getProductsFromServer();
    this.getMaterialsFromServer();
  },
}
</script>
