<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>

                    <q-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewUnitDialog"
                        style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;"
                    >
                      <v-icon small>mdi-pen-plus</v-icon>
                      افزودن واحد جدید
                    </q-btn>


                  </v-btn-toggle>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredUnits"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.symbol }}</td>
                      <td>{{ item.conversionFactor }}</td>
                      <td>{{ item.baseUnitName }}</td>
                      <td>{{ item.description }}</td>

                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.active),
          color: getStatusTextColor(item.active),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.active) }}
      </span>
                      </td>
                      <td>
                        <q-btn-group v-if="$q.screen.lt.md">
                          <q-btn
                              small
                              color="primary"
                              @click="openEditUnitDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn
                              small
                              :color="item.active ? 'grey' : 'success'"
                              @click="toggleActive(item)"
                          >
                            <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.active ? 'غیرفعال شود' : 'فعال شود' }}
                          </q-btn>
                        </q-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditUnitDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            style="margin-right: 15px"
                            small
                            :color="item.active ? 'grey' : 'success'"
                            @click="toggleActive(item)"
                        >
                          <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.active ? 'غیرفعال شود' : 'فــعـال شــود' }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="editDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedUnit.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedUnit.name"
                          label="نام واحد"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedUnit.symbol"
                          label="نماد واحد"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedUnit.conversionFactor"
                          label="ضریب تبدیل"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedUnit.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newUnitDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewUnitDialog">mdi-close</v-icon>
                <span class="headline">افزودن واحد جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newUnitData.name"
                          label="نام واحد"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newUnitData.symbol"
                          label="نماد واحد"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newUnitData.conversionFactor"
                          label="ضریب تبدیل"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">

                      <v-select
                          v-model="newUnitData.baseUnitSymbolId"
                          :items="filterActiveUnits"
                          item-title="name"
                          item-value="id"
                          outlined
                          dense
                          style="font-family: 'fontBody', sans-serif;border-radius: 15px; font-size: 18px;background: #ffffff"
                      >
                        <template v-slot:item="{ props,item }">
                          <v-list-item
                              v-bind="props"
                              style="font-family: 'fontBody', sans-serif; font-size: 18px"
                              :style="{
              color: getStatusTextColor(item.id)
            }"
                          >

                          </v-list-item>
                        </template>
                      </v-select>

                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="newUnitData.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewUnit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="400"
        style="font-family: 'fontBody', sans-serif;font-size: 18px"
    >
      <v-card>
        <v-card-title class="headline">حذف واحد</v-card-title>
        <v-card-text>آیا از حذف واحد "{{ unitToDelete.name }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteUnit">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';


export default {
  name: 'UnitManagement',
  data() {
    return {
      date: '',
      deleteDialog: false,
      unitToDelete: null,
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',
      filterStatus: 'all',
      editDialog: false,
      newUnitDialog: false,
      editedIndex: -1,
      newUnitData: {
        name: '',
        symbol: '',
        baseUnitSymbolId: '',
        conversionFactor: '',
        description: ''
      },
      editedUnit: {
        id: 1,
        name: '',
        symbol: '',
        conversionFactor: '',
        description: '',
        active: true
      },
      headers: [
        {
          title: 'شناسه',
          align: 'start',
          sortable: true,
          value: 'id',
        }, {
          title: 'نام',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          title: 'نماد',
          align: 'start',
          sortable: true,
          value: 'symbol',
        },
        {
          title: 'ضریب تبدیل',
          align: 'start',
          sortable: true,
          value: 'conversionFactor',
        },
        {
          title: 'والد',
          align: 'start',
          sortable: true,
          value: 'baseUnitName',
        },
        {
          title: 'توضیحات',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          title: 'وضعیت',
          align: 'start',
          sortable: true,
          value: 'active',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      slcUnits: [],
      units: [
        // {
        //   id: 1,
        //   name: 'گرم',
        //   symbol: 'gr',
        //   conversionFactor: 1.2,
        //   description: 'توضیح',
        //   active: true,
        //   derivedUnits: [
        //     {
        //       id: 1002,
        //       name: 'کیلوگرم',
        //       symbol: 'kg',
        //       conversionFactor: 1.2,
        //       description: 'توضیح',
        //       active: true,
        //       derivedUnits: []
        //     }
        //   ]
        // }
      ],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils
  },
  computed: {
    filteredUnits() {
      let filtered = this.units
      if (this.filterStatus === 'active') {
        filtered = filtered.filter((unit) => unit.active)
      } else if (this.filterStatus === 'inactive') {
        filtered = filtered.filter((unit) => !unit.active)
      }
      return filtered
    },

    filterActiveUnits() {
      return this.units.filter(unit => unit.active);
    }


  },
  methods: {
    getDate() {
      console.log(this.date)
    },
    getStatusColor(status) {
      switch (status) {
        case true:
          return '#D4EDDA';
        case false:
          return '#F8D7DA';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case true:
          return '#155724';
        case false:
          return '#721C24';
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return 'فعال';
        case false:
          return 'غیرفعال';
      }
    },
    toggleActive(item) {
      let beforeState = item.active;
      const changeActiveUnit = {
        id: item.id,
        name: item.name,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveUnit);
      ApiService.post("/unit/updateStatus", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedUnits = this.units.map(unit => {
          if (unit.id === item.id) {
            return {...unit, active: !beforeState};
          }
          return unit;
        });
        this.units = updatedUnits;

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    openDeleteDialog(unit) {
      this.unitToDelete = unit;
      this.deleteDialog = true;
    },
    confirmDeleteUnit() {
      const deleteUnit = {
        id: this.unitToDelete.id,
        name: this.unitToDelete.name,
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(deleteUnit);

      console.log(requestBody)
      ApiService.post("/unit/delete", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.deleteUnit(this.unitToDelete);
        this.deleteDialog = false;
        console.log(response)
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteUnit(unit) {
      const index = this.units.findIndex(u => u.id === unit.id);
      if (index !== -1) {
        this.units.splice(index, 1);
      }
    },
    openEditUnitDialog(unit) {
      this.editedIndex = this.units.indexOf(unit)
      this.editedUnit = Object.assign({}, unit)
      this.editDialog = true
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedUnit = Object.assign({}, this.defaultUnit)
        this.editedIndex = -1
      })
    },
    closeNewUnitDialog() {
      this.newUnitDialog = false
    },
    openNewUnitDialog() {
      this.newUnitDialog = true
    },
    saveEdit() {
      let editedUnitData = this.editedUnit;

      const newEditUnitData = {
        id: editedUnitData.id,
        name: editedUnitData.name,
        symbol: editedUnitData.symbol,
        conversionFactor: editedUnitData.conversionFactor,
        description: editedUnitData.description
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditUnitData);
      console.log(requestBody)
      ApiService.post("/unit/update", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()

        if (this.editedIndex > -1) {
          Object.assign(this.units[this.editedIndex], this.editedUnit)
        }

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    saveNewUnit() {
      const newUnitData = {
        name: this.newUnitData.name,
        symbol: this.newUnitData.symbol,
        conversionFactor: this.newUnitData.conversionFactor,
        description: this.newUnitData.description,
        baseUnitSymbolId: this.newUnitData.baseUnitSymbolId
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newUnitData);
      console.log(requestBody)
      ApiService.post("/unit/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewUnitDialog();
        this.newUnitDataValue();

        this.units.unshift({
          ...newUnitData,
          id: response.data.id,
          baseUnitName: response.data.baseUnitName,
          active: true,
          derivedUnits: []
        })

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    newUnitDataValue() {
      this.newUnitData = {
        name: '',
        symbol: '',
        conversionFactor: '',
        description: ''
      };
    },
    getUnitListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/unit/getAllUnits", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.units = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    }
  },
  mounted() {
    this.getUnitListFromServer();
  },
}
</script>
