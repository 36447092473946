<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <q-btn dense outlined small color="success" @click="openNewMaterialDialog"
                         style="position: absolute; left: 0; background-color: #343941; color: #9da7b6;">
                    <v-icon small>mdi-pen-plus</v-icon>
                    افزودن ماده جدید
                  </q-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="materials"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.fromDate }}</td>
                      <td>{{ item.inventory }}</td>
                      <td>{{ item.reorderLevel }}</td>
                      <td>{{ item.unitOfMeasure.name }}</td>

                      <td>
                        <v-btn-group v-if="$q.screen.lt.md">
                          <q-btn
                              small
                              color="primary"
                              @click="openEditMaterialDialog(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <q-btn
                              @click="openDeleteDialog(item)"
                              small
                              color="error"
                          >
                            <v-icon small>mdi-delete</v-icon>
                            حذف
                          </q-btn>
                          <q-btn
                              small
                              color="info"
                              @click="openMaterialItemList(item)"
                          >
                            <v-icon small>mdi-view-list</v-icon>
                            آیتم ها
                          </q-btn>
                        </v-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditMaterialDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="error"
                            class="mr-2"
                            @click="openDeleteDialog(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="info"
                            class="mr-2"
                            @click="openMaterialItemList(item)"
                        >
                          <v-icon small>mdi-view-list</v-icon>
                          آیتم ها
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog
              v-model="editDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedMaterial.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMaterial.name"
                          label="نام ماده"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMaterial.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="editedMaterial.unitOfMeasureId"
                          :items="units"
                          item-title="name"
                          item-value="id"
                          label="واحد اندازه گیری"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedMaterial.reorderLevel"
                          label="حداقل موجودی"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newMaterialDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewMaterialDialog">mdi-close</v-icon>
                <span class="headline">افزودن ماده جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMaterialData.name"
                          label="نام ماده"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMaterialData.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="newMaterialData.unitOfMeasureId"
                          :items="units"
                          item-title="name"
                          item-value="id"
                          label="واحد اندازه گیری"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newMaterialData.reorderLevel"
                          label="حداقل موجودی"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewMaterial">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="materialItemDialog"
              fullscreen
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeMaterialItemDialog">mdi-close</v-icon>
                <span class="headline">لیست موجودی </span>
                <span class="headline" style="font-weight: bold">{{ selectedMaterial.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-data-table
                    :headers="itemHeaders"
                    :items="selectedMaterial.materialItems"
                    mobile-breakpoint="600"
                >
                  <template v-slot:top>
                    <v-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewItemDialog"
                        style="margin-bottom: 10px;"
                    >
                      <v-icon small>mdi-plus</v-icon>
                      افزودن آیتم جدید
                    </v-btn>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.expiryDate }}</td>
                      <td>{{ item.batchNumber }}</td>
                      <td>{{ formatPersianDate(item.fromDate) }}</td>
                      <td>{{ item.quantity + ' ' + item.unitOfMeasureName }}</td>
                      <td>{{ item.consumedQuantity + ' ' + item.unitOfMeasureName }}</td>
                      <td>{{ item.remainingQuantity + ' ' + item.unitOfMeasureName }}</td>
                      <td>
                        <v-btn
                            small
                            color="error"
                            @click="deleteItem(item)"
                        >
                          <v-icon small>mdi-delete</v-icon>
                          حذف
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog
              v-model="newItemDialog"
              max-width="500px"
              style="font-family: 'fontBody', sans-serif;font-size: 18px"
          >
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewItemDialog">mdi-close</v-icon>
                <span class="headline">افزودن آیتم جدید به </span>
                <span class="headline" style="font-weight: bold">{{ selectedMaterial.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newItemData.description"
                          label="توضیحات"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newItemData.batchNumber"
                          label="شماره سریال"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          v-model="newItemData.quantity"
                          label="مقدار"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="newItemData.unitOfMeasureId"
                          :items="getSelectUnitsNewItems()"
                          item-title="title"
                          item-value="value"
                          label="واحد اندازه گیری"
                      ></v-select>
                    </v-col>
                    <!--                    <v-col cols="12">-->
                    <!--                      <v-text-field-->
                    <!--                          v-model="newItemData.expiryDate"-->
                    <!--                          label="تاریخ انقضا"-->
                    <!--                      ></v-text-field>-->
                    <!--                    </v-col>-->

                    <v-col cols="12">
                      <DatePicker
                          style="font-family: 'fontBody', sans-serif"
                          v-model="newItemData.expiryDate"
                          display-format="jYYYY-jMM-jDD"
                          format="jYYYY-jMM-jDD"
                          placeholder="انتخاب تاریخ"
                          :auto-submit="true"
                          label="تاریخ انقضاء"
                          simple/>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="newItemData.totalPrice"
                          label="قیمت کل این مواد(تومان)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewItem">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="400"
        style="font-family: 'fontBody', sans-serif;font-size: 18px"
    >
      <v-card>
        <v-card-title class="headline">حذف ماده</v-card-title>
        <v-card-text>آیا از حذف ماده "{{ materialToDelete.name }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteMaterial">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>
<script>

import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';
import jalaliMoment from 'jalali-moment'


export default {
  name: 'MaterialManagement',
  data() {
    return {
      deleteDialog: false,

      materialToDelete: null,
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      editDialog: false,
      newMaterialDialog: false,
      materialItemDialog: false,
      newItemDialog: false,
      selectedMaterial: null,
      editedIndex: -1,
      newMaterialData: {name: '', description: '', unitOfMeasureId: null, reorderLevel: null},
      newItemData: {materialId: null, description: '', batchNumber: '', quantity: null, expiryDate: '', totalPrice: ''},
      editedMaterial: {id: null, name: '', description: '', unitOfMeasureId: null, reorderLevel: null},
      headers: [{title: 'شناسه', align: 'start', sortable: true, value: 'id',}, {
        title: 'نام',
        align: 'start',
        sortable: true,
        value: 'name',
      }, {title: 'توضیحات', align: 'start', sortable: true, value: 'description',}, {
        title: 'تاریخ', align: 'start',
        sortable: true,
        value: 'fromDate',
      },
        {
          title: 'موجودی',
          align: 'start',
          sortable: true,
          value: 'inventory',
        },
        {
          title: 'حداقل موجودی',
          align: 'start',
          sortable: true,
          value: 'reorderLevel',
        },
        {
          title: 'واحد اندازه گیری',
          align: 'start',
          sortable: true,
          value: 'unitOfMeasure.name',
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],
      itemHeaders: [
        {
          title: 'شناسه',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          title: 'تاریخ انقضا',
          align: 'start',
          sortable: true,
          value: 'expiryDate',
        },
        {
          title: 'شماره سریال',
          align: 'start',
          sortable: true,
          value: 'batchNumber',
        },
        {
          title: 'تاریخ ثبت',
          align: 'start',
          sortable: true,
          value: 'fromDate',
        },
        {
          title: 'مقدار ثبت شده',
          align: 'start',
          sortable: true,
          value: 'quantity',
        }, {
          title: 'مقدار مصرف شده',
          align: 'start',
          sortable: true,
          value: 'consumedQuantity',
        }, {
          title: 'مقدار مانده',
          align: 'start',
          sortable: true,
          value: 'remainingQuantity',
        },
        {
          title: 'عملیات',
          align: 'start',
          sortable: false,
          value: 'manage',
        },
      ],

      materials: [],
      units: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils
  },
  methods: {
    formatPersianDate(dateString) {
      const miladi = new Date(dateString)
      const shamsi = jalaliMoment(miladi).format('jYYYY/jM/jD HH:mm:ss')
      return shamsi;
    },
    convertQuantities(quantity) {
      const unitOfMeasure = this.selectedMaterial.unitOfMeasure;

      if (!unitOfMeasure) {
        return `${quantity}\n`;
      }

      const units = unitOfMeasure.derivedUnits;

      const convertedQuantities = units?.map(unit => {
        if (unit?.conversionFactor) {
          const convertedValue = quantity / unit.conversionFactor;
          return `${convertedValue} ${unit.name}`;
        }
      })?.filter(Boolean) || [];

      if (convertedQuantities.length === 0) {
        convertedQuantities.push(`${quantity} ${unitOfMeasure.name}`);
      }

      console.log(convertedQuantities.join('\n'));
      return convertedQuantities.join('\n');
    },
    getSelectUnitsNewItems() {
      const unitOfMeasure = this.selectedMaterial.unitOfMeasure;
      const units = [];

      if (unitOfMeasure && unitOfMeasure.baseUnitId) {
        if (unitOfMeasure.subsetUnits && Array.isArray(unitOfMeasure.subsetUnits)) {
          unitOfMeasure.subsetUnits.forEach(unit => {
            if (unit && unit.id && unit.name) {
              units.push({
                value: unit.id,
                title: unit.name
              });
            }
          });
        }
      } else if (unitOfMeasure && unitOfMeasure.derivedUnits && Array.isArray(unitOfMeasure.derivedUnits)) {
        unitOfMeasure.derivedUnits.forEach(unit => {
          if (unit && unit.id && unit.name) {
            units.push({
              value: unit.id,
              title: unit.name
            });
          }
        });
      }
      return units;
    },
    openDeleteDialog(material) {
      this.materialToDelete = material;
      this.deleteDialog = true;
    }
    ,
    confirmDeleteMaterial() {
      this.deleteMaterial(this.materialToDelete);
      this.deleteDialog = false;
    }
    ,
    deleteMaterial(material) {

      const deleteItemId = material.id;

      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/material/delete?id=${deleteItemId}`, this.config).then(response => {
        console.log(response)
        this.$refs.uiUtils.dismissIsLoading();

        const index = this.materials.findIndex(m => m.id === material.id);
        if (index !== -1) {
          this.materials.splice(index, 1);
        }
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    }
    ,
    openEditMaterialDialog(material) {
      this.editedIndex = this.materials.indexOf(material)
      this.editedMaterial = Object.assign({}, material)
      this.editDialog = true
    }
    ,
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedMaterial = Object.assign({}, this.defaultMaterial)
        this.editedIndex = -1
      })
    }
    ,
    closeNewMaterialDialog() {
      this.newMaterialDialog = false
    }
    ,
    openNewMaterialDialog() {
      this.newMaterialDialog = true
    }
    ,
    openMaterialItemList(material) {
      console.log("openMaterialItemList : ", material)
      this.selectedMaterial = material;
      this.materialItemDialog = true;
    }
    ,
    closeMaterialItemDialog() {
      this.materialItemDialog = false;
    }
    ,
    openNewItemDialog() {
      this.newItemData.materialId = this.selectedMaterial.id;
      this.newItemDialog = true;
    }
    ,
    closeNewItemDialog() {
      this.newItemDialog = false;
    }
    ,
    saveEdit() {
      let editedMaterialData = this.editedMaterial;

      const newEditMaterialData = {
        id: editedMaterialData.id,
        name: editedMaterialData.name,
        description: editedMaterialData.description,
        unitOfMeasureId: editedMaterialData.unitOfMeasureId,
        reorderLevel: editedMaterialData.reorderLevel
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newEditMaterialData);

      ApiService.post("/material/update", requestBody, this.config).then(response => {
        console.log("response : ", response)
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()

        if (this.editedIndex > -1) {
          Object.assign(this.materials[this.editedIndex], this.editedMaterial)
        }

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    }
    ,
    saveNewMaterial() {
      const newMaterialData = {
        name: this.newMaterialData.name,
        description: this.newMaterialData.description,
        unitOfMeasureId: this.newMaterialData.unitOfMeasureId,
        reorderLevel: this.newMaterialData.reorderLevel
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newMaterialData);

      ApiService.post("/material/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewMaterialDialog();
        this.newMaterialDataValue();

        this.materials.unshift({
          ...newMaterialData,
          id: response.data.id,
          unitOfMeasure: this.units.find(unit => unit.id === newMaterialData.unitOfMeasureId),
          materialItems: []
        })

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    }
    ,
    saveNewItem() {
      const newItemData = {
        materialId: this.newItemData.materialId,
        description: this.newItemData.description,
        batchNumber: this.newItemData.batchNumber,
        quantity: this.newItemData.quantity,
        expiryDate: this.newItemData.expiryDate,
        unitOfMeasureId: this.newItemData.unitOfMeasureId,
        totalPrice: this.newItemData.totalPrice
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newItemData);

      ApiService.post("/material/item/create", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewItemDialog();
        this.newItemDataValue();
        console.log(response)
        this.selectedMaterial.materialItems.unshift({
          ...response.data.materialItems[0]
        })


        const index = this.materials.findIndex(material => material.id === response.data.id);

        if (index !== -1) {
          this.materials = this.materials.map(material => {
            if (material.id === response.data.id) {
              return response.data;
            }
            return material;
          });
        } else {
          this.materials.push(response.data);
        }

      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    }
    ,
    newMaterialDataValue() {
      this.newMaterialData = {
        name: '',
        description: '',
        unitOfMeasureId: null,
        reorderLevel: null
      };
    }
    ,
    newItemDataValue() {
      this.newItemData = {
        materialId: null,
        description: '',
        batchNumber: '',
        quantity: null,
        expiryDate: ''
      };
    }
    ,
    deleteItem(item) {
      const deleteItemId = item.id;
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/material/delete/item?itemId=${deleteItemId}`, this.config).then(response => {
        console.log(response)
        const indexi = this.selectedMaterial.materialItems.findIndex(i => i.id === item.id);
        if (indexi !== -1) {
          this.selectedMaterial.materialItems.splice(indexi, 1);
        }

        const index = this.materials.findIndex(material => material.id === response.data.id);

        if (index !== -1) {
          this.materials = this.materials.map(material => {
            if (material.id === response.data.id) {
              return response.data;
            }
            return material;
          });
        } else {
          this.materials.push(response.data);
        }

        this.$refs.uiUtils.dismissIsLoading();
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading();
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })

    }
    ,
    getMaterialListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/material/getAllMaterials", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.materials = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    }
    ,
    getUnitListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/unit/getAllUnits", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.units = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    }
  }
  ,
  mounted() {
    this.getMaterialListFromServer();
    this.getUnitListFromServer();

  }
  ,
}
</script>
<style scoped>
div :deep(.persianDatePicker),
div :deep([disabled]),
div :deep(.vpd-day-text),
div :deep(.vpd-day-effect) {
  font-family: 'fontBody', sans-serif !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

div :deep([disabled]:hover) {
  opacity: 0.8 !important;
}

.custom-date {
  border: 0.5px solid gray;
  border-radius: 3px;
}

.custom-date:hover {
  border: 1px solid black;
}

div :deep(.persianDatePicker) > span {
  height: 37px;
  overflow: hidden;
}
</style>
