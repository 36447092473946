<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="جستجو ..."
                      solo
                      flat
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn-toggle v-model="filterStatus" dense outlined style="margin-top: 10px;">
                    <v-btn
                        value="all"
                        :class="{ 'active': filterStatus === 'all' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >همه
                    </v-btn>
                    <v-btn
                        value="active"
                        :class="{ 'active': filterStatus === 'active' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >فعال
                    </v-btn>
                    <v-btn
                        value="inactive"
                        :class="{ 'active': filterStatus === 'inactive' }"
                        style="background-color: #343941; color: #9da7b6;"
                    >غیرفعال
                    </v-btn>

                    <q-btn
                        dense
                        outlined
                        small
                        color="success"
                        @click="openNewDeskDialog"
                        style="position: absolute; left: 0;"
                    >
                      <v-icon small>mdi-pen-plus</v-icon>
                      افزودن میز جدید
                    </q-btn>
                  </v-btn-toggle>
                </v-card-title>

                <v-data-table
                    :headers="headers"
                    :items="filteredDesks"
                    :search="search"
                    page-text="{0}-{1} از {2}"
                    items-per-page-text="تعداد در صفحه : "
                    :items-per-page-options="probCountInpPage"
                    mobile-breakpoint="600"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.active),
          color: getStatusTextColor(item.active),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.active) }}
      </span>
                      </td>
                      <td>
      <span
          :style="{
          backgroundColor: getStatusColor(item.chatActive),
          color: getStatusTextColor(item.chatActive),
          padding: '4px 8px',
          borderRadius: '4px'
        }"
      >
        {{ getStatusText(item.chatActive) }}
      </span>
                      </td>

                      <td>{{ item.number }}</td>
                      <td>{{ item.userCount }}</td>
                      <td>{{ getBranch(item) }}</td>
                      <td>{{ item.lastUpdateDate }}</td>


                      <td>
                        <q-btn-group v-if="$q.screen.lt.md">
                          <q-btn small color="primary" @click="openEditDeskDialog(item)">
                            <v-icon small>mdi-pencil</v-icon>
                            ویرایش
                          </q-btn>
                          <!--                          <q-btn @click="openDeleteDialog(item)" small color="error">-->
                          <!--                            <v-icon small>mdi-delete</v-icon>-->
                          <!--                            حذف-->
                          <!--                          </q-btn>-->
                          <q-btn
                              small
                              :color="item.active ? 'grey' : 'success'"
                              @click="toggleActive(item)"
                          >
                            <v-icon small>{{ item.chatActive ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.chatActive ? 'غیرفعال شود' : 'فعال شود' }}
                          </q-btn>
                          <q-btn
                              small
                              :color="item.chatActive ? 'grey' : 'success'"
                              @click="toggleActiveChat(item)"
                          >
                            <v-icon small>{{ item.chatActive ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                            {{ item.chatActive ? 'چت غیرفعال شود' : 'چت فعال شود' }}
                          </q-btn>


                        </q-btn-group>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            small
                            color="primary"
                            class="ml-2"
                            @click="openEditDeskDialog(item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                          ویرایش
                        </v-btn>
                        <!--                        <v-btn-->
                        <!--                            v-if="!$q.screen.lt.md"-->
                        <!--                            small-->
                        <!--                            color="error"-->
                        <!--                            class="mr-2"-->
                        <!--                            @click="openDeleteDialog(item)"-->
                        <!--                        >-->
                        <!--                          <v-icon small>mdi-delete</v-icon>-->
                        <!--                          حذف-->
                        <!--                        </v-btn>-->
                        <v-btn
                            v-if="!$q.screen.lt.md"
                            style="margin-right: 15px"
                            small
                            :color="item.active ? 'grey' : 'success'"
                            @click="toggleActive(item)"
                        >
                          <v-icon small>{{ item.active ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.active ? 'غیرفعال شود' : 'فــعـال شــود' }}
                        </v-btn>

                        <v-btn
                            v-if="!$q.screen.lt.md"
                            style="margin-right: 15px"
                            small
                            :color="item.chatActive ? 'grey' : 'success'"
                            @click="toggleActiveChat(item)"
                        >
                          <v-icon small>{{ item.chatActive ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
                          {{ item.chatActive ? 'چت غیرفعال شود' : 'چت فــعـال شــود' }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="editDialog" max-width="500px" style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeEdit">mdi-close</v-icon>
                <span class="headline">ویرایش </span>
                <span class="headline" style="font-weight: bold">{{ editedDesk.name }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedDesk.name" label="نام میز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedDesk.number" label="شماره میز" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedDesk.userCount" label="تعداد صندلی" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="editedDesk.deskBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveEdit">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newDeskDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="closeNewDeskDialog">mdi-close</v-icon>
                <span class="headline">افزودن میز جدید </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newDeskData.name" label="نام میز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newDeskData.number" label="شماره میز" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newDeskData.userCount" label="تعداد صندلی" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          style="font-family: 'fontBody', sans-serif; font-size: 18px"
                          v-model="newDeskData.deskBranch"
                          :items="branchTypes"
                          item-title="title"
                          item-value="value"
                          label="بخش"
                          return-object
                      >
                        <template v-slot:item="{ props }">
                          <v-list-item v-bind="props" style="font-family: 'fontBody', sans-serif; font-size: 18px">
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="saveNewDesk">
                  ذخیره
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

    <v-dialog v-model="deleteDialog" persistent max-width="400"
              style="font-family: 'fontBody', sans-serif;font-size: 18px">
      <v-card>
        <v-card-title class="headline">حذف میز</v-card-title>
        <v-card-text>آیا از حذف میز "{{ deskToDelete.name }}" اطمینان دارید؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
          <v-btn color="error" text @click="confirmDeleteDesk">حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'DeskManagement',
  data() {
    return {
      isUploading: false,
      uploadStatus: null,
      deleteDialog: false,
      deskToDelete: null,
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',
      filterStatus: 'all',
      editDialog: false,
      newDeskDialog: false,
      editedIndex: -1,
      newDeskData: {
        name: '',
        number: '',
        userCount: 0,
        active: true,
        img: ''
      },
      editedDesk: {
        id: 1,
        name: '',
        number: '',
        img: '',
        userCount: 0,
        active: true
      },
      headers: [
        {
          title: 'شناسه',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          title: 'نام',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          title: 'وضعیت',
          align: 'left',
          sortable: true,
          value: 'active'
        }, {
          title: 'چت',
          align: 'left',
          sortable: true,
          value: 'chatActive'
        }, {
          title: 'شماره',
          align: 'start',
          sortable: true,
          value: 'number'
        },
        {
          title: 'تعداد صندلی',
          align: 'start',
          sortable: true,
          value: 'userCount'
        },
        {
          title: 'بخش',
          align: 'start',
          sortable: true,
          value: 'deskBranch'
        },{
          title: 'بروز شده',
          align: 'start',
          sortable: true,
          value: 'lastUpdateDate'
        },
        {
          title: 'مدیریت',
          align: 'start',
          sortable: false,
          value: 'manage'
        }
      ],
      desks: [{
        id: 1003,
        name: "میز شماره چهار",
        userCount: 3,
        number: 4,
        active: true,
        chatActive: true,
        fromDate: "۱۴۰۲ اسفند ۱۵, سه‌شنبه، ۱۲:۴۶:۳۹",
        userCreated: "مدیر کل",
      }],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
      branchTypes: [
        {
          value: 'COFFEE_SHOP',
          title: 'کافی شاپ'
        },
        {
          value: 'RESTAURANT',
          title: 'رستوران'
        }
      ],
    }
  },
  components: {
    UiUtils
  },
  computed: {
    filteredDesks() {
      let filtered = this.desks
      if (this.filterStatus === 'active') {
        filtered = filtered.filter((desk) => desk.active)
      } else if (this.filterStatus === 'inactive') {
        filtered = filtered.filter((desk) => !desk.active)
      }
      return filtered
    }
  },
  methods: {
    getBranch(item) {
      if (item.deskBranch) {
        return item.deskBranch.title;
      } else {
        return '';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case true:
          return '#D4EDDA';
        case false:
          return '#F8D7DA';
      }
    },
    getStatusTextColor(status) {
      switch (status) {
        case true:
          return '#155724';
        case false:
          return '#721C24';
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return 'فعال';
        case false:
          return 'غیرفعال';
      }
    },
    toggleActive(item) {
      const changeActiveDesk = {
        id: item.id,
        name: '',
        updateType: 'status',
        userCount: 0,
        number: 0,
        active: false
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveDesk);
      ApiService.post("/desk/updateDesk", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedDesks = this.desks.map(desk => {
          if (desk.id === item.id) {
            return response.data
          }
          return desk;
        });
        this.desks = updatedDesks;

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    toggleActiveChat(item) {
      const changeActiveDesk = {
        id: item.id,
        name: '',
        updateType: 'statusChat',
        userCount: 0,
        number: 0,
        active: false
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(changeActiveDesk);
      ApiService.post("/desk/updateDesk", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        const updatedDesks = this.desks.map(desk => {
          if (desk.id === item.id) {
            return response.data
          }
          return desk;
        });
        this.desks = updatedDesks;

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    deleteDesk(desk) {
      const index = this.desks.findIndex(d => d.id === desk.id);
      if (index !== -1) {
        this.desks.splice(index, 1);
      }
    },
    openEditDeskDialog(desk) {
      console.log("openEditDeskDialog : ", desk)
      this.editedIndex = this.desks.indexOf(desk)
      this.editedDesk = Object.assign({}, desk)
      console.log("this.editedDesk : ", this.editedDesk)

      this.editDialog = true
    },
    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedDesk = Object.assign({}, this.defaultDesk)
        this.editedIndex = -1
      })
    },
    closeNewDeskDialog() {
      this.newDeskDialog = false
    },
    openNewDeskDialog() {
      this.newDeskDialog = true
    },
    saveEdit() {
      console.log("saveEdit : ", this.editedDesk)
      let editedDeskData = this.editedDesk;

      if (!editedDeskData.deskBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      const editDesk = {
        id: editedDeskData.id,
        name: editedDeskData.name,
        updateType: 'data',
        userCount: editedDeskData.userCount,
        number: editedDeskData.number,
        deskBranch: editedDeskData.deskBranch.value,
        active: false
      };

      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(editDesk);
      console.log(requestBody)
      ApiService.post("/desk/updateDesk", requestBody, this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading();
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeEdit()

        const updatedDesks = this.desks.map(desk => {
          if (desk.id === editDesk.id) {
            return response.data
          }
          return desk;
        });
        this.desks = updatedDesks;

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    async saveNewDesk() {

      if (!this.newDeskData.deskBranch) {
        this.$refs.uiUtils.showSnackErrorMessage("لطفا بخش را انتخاب کنید");
        return;
      }

      const newDeskData = {
        name: this.newDeskData.name,
        number: this.newDeskData.number,
        userCount: this.newDeskData.userCount,
        img: this.newDeskData.img,
        deskBranch: this.newDeskData.deskBranch.value,
        active: true
      };
      this.$refs.uiUtils.showIsLoading();
      const requestBody = JSON.stringify(newDeskData);
      console.log(requestBody)
      ApiService.post("/desk/create", requestBody, this.config).then(response => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        console.log(response)
        this.$refs.uiUtils.showSuccessDialog();
        this.closeNewDeskDialog();
        this.newDeskDataValue();

        this.desks = response.deskList

      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading();
        }
        this.$refs.uiUtils.showSnackErrorMessage(error.message);
      })
    },
    newDeskDataValue() {
      this.newDeskData = {
        name: '',
        number: '',
        imgNewDeskUrl: '',
        imgNewDeskFileId: ''
      };
    },
    getDeskListFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/desk/getAllDeskList", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(response)
        this.desks = response.deskList;
      }).catch(error => {
        if (this.$refs.uiUtils) {
          this.$refs.uiUtils.dismissIsLoading()
        }
        console.log(error)
      })
    }
  },
  mounted() {
    this.getDeskListFromServer();
  },
}
</script>
