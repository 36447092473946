<template>
  <v-locale-provider rtl style="font-family: 'fontBody', sans-serif;font-size: 18px;background-color: #343941;">
    <v-card>
      <v-layout style="height: 100vh;background-color: #343941;">
        <v-navigation-drawer v-model="drawer" :rail="rail" permanent @click="rail = false"
                             style="background-color: #343941;color: #9da7b6">
          <v-list-item prepend-icon="mdi-account-circle" prepend-icon-color="primary" title="پنل مدیریت" nav>
            <template v-slot:append>
              <v-btn icon="mdi-chevron-right" variant="text" @click.stop="rail = !rail"></v-btn>
            </template>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <menu-item
                v-for="(item, index) in menus"
                :key="index"
                :item="item"
                @select-item="setCurrentComponent"
                @deactivate-items="deactivateItems"
                @activate-item="activateItem"
            ></menu-item>
          </v-list>
        </v-navigation-drawer>
        <v-main style="flex: 1; overflow-y: auto;">
          <component :is="currentComponent"></component>
        </v-main>
      </v-layout>
    </v-card>
  </v-locale-provider>
</template>

<script>
import AdminCategory from '../components/AdminCategory.vue';
import AdminProduct from '../components/AdminProduct.vue';
import AdminOrder from '../components/AdminOrder.vue';
import AdminChat from '../components/AdminChat.vue';
import AdminDesk from '../components/AdminDesk.vue';
import AdminReservationDesk from '../components/AdminReservationDesk.vue';
import AdminMusic from '../components/AdminMusic.vue';
import AdminStore from '../components/AdminStore.vue';
import AdminBilling from '../components/AdminBilling.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import AdminFormula from '../components/AdminFormula.vue';
import AccountManagement from '../components/AccountManagement.vue';
import TransactionManagement from '../components/TransactionManagement.vue';
import UnitMeasurement from '../components/UnitMeasurement.vue';
import StakeholderManagement from '../components/StakeholderManagement.vue';
import AccountCategoryManagement from '../components/AccountCategoryManagement.vue';
import ProductionQuantities from '../components/ProductionQuantities.vue';
import CalculateSimultaneousProductionModule from '../components/CalculateSimultaneousProduction.vue';
import CalculateProductionCostModule from '../components/CalculateProductionCost.vue';
import AdminAuth from '../components/AdminAuth.vue';
import CalculateMaterialsNeededModule from '../components/CalculateMaterialsNeeded.vue';
import Material from '../components/AdminMaterial.vue';
import MenuItem from '../components/MenuItem.vue';

export default {
  name: 'AdminPanel',
  components: {
    TransactionManagement,
    AdminCategory,
    AccountCategoryManagement,
    CalculateProductionCostModule,
    CalculateMaterialsNeededModule,
    AdminProduct,
    AdminOrder,
    AdminChat,
    AdminAuth,
    AdminDesk,
    AdminReservationDesk,
    AdminMusic,
    AdminStore,
    AdminBilling,
    AdminDashboard,
    UnitMeasurement,
    Material,
    AdminFormula,
    StakeholderManagement,
    AccountManagement,
    ProductionQuantities,
    CalculateSimultaneousProductionModule,
    MenuItem
  },
  data() {
    return {
      drawer: true,
      rail: true,
      currentComponent: 'CategoryManage',
      menus: [
        {title: 'داشبورد', icon: 'mdi-monitor-dashboard', value: 'AdminDashboard'},
        {title: 'سفارشات', icon: 'mdi-cart-arrow-up', value: 'AdminOrder'},
        {title: 'دسته بندی', icon: 'mdi-set-split', value: 'AdminCategory'},
        // {
        //   title: 'محصول',
        //   icon: 'mdi-file-edit-outline',
        //   value: null,
        //   submenu: [
        //     {title: 'افزودن محصول', value: 'AddProduct'},
        //     {title: 'محصولات قبلی', value: 'AdminProduct'}
        //   ]
        // },
        {title: 'محصول', icon: 'mdi-file-edit-outline', value: 'AdminProduct'},
        {title: 'چت', icon: 'mdi-chat-processing-outline', value: 'AdminChat'},
        {title: 'موزیک', icon: 'mdi-music-clef-treble', value: 'AdminMusic'},
        {title: 'میز', icon: 'mdi-table-chair', value: 'AdminDesk'},
        {title: 'رزو', icon: 'mdi-calendar-range', value: 'AdminReservationDesk'},
        {title: 'دسترسی', icon: 'mdi-account-key-outline', value: 'AdminAuth'},
        // {title: 'انبار داری', icon: 'mdi-database-edit-outline', value: 'AdminStore'},
        // {title: 'حسابداری', icon: 'mdi-currency-usd', value: 'AdminBilling'},


        {
          title: 'حسابداری',
          icon: 'mdi-currency-usd',
          value: null,
          submenu: [
            {title: 'حساب', value: 'AccountManagement'},
            {title: 'دسته بندی درآمد/هزینه', value: 'AccountCategoryManagement'},
            {title: 'درآمد/هزینه', value: 'TransactionManagement'},
            {title: 'جاری شرکا', value: 'StakeholderManagement'},
            {title: 'گزارشات', value: 'Material'},
            {title: 'تسهیم', value: 'Material'},
          ]
        },

        {
          title: 'انبار داری',
          icon: 'mdi-database-edit-outline',
          value: null,
          submenu: [
            {title: 'واحد اندازه گیری', value: 'UnitMeasurement'},
            {title: 'مواد اولیه', value: 'Material'},
            {title: 'فرمول ساخت', value: 'AdminFormula'},
            {title: 'مقدار تولید', value: 'ProductionQuantities'},
            {title: 'مقدار تولید همزمانی', value: 'CalculateSimultaneousProductionModule'},
            {title: 'مواد اولیه مورد نیاز', value: 'CalculateMaterialsNeededModule'},
            {title: 'هزینه تولید محصول', value: 'CalculateProductionCostModule'},
          ]
        },


      ]
    };
  },
  methods: {
    setCurrentComponent(componentName) {
      this.currentComponent = componentName;
    },
    deactivateItems() {
      this.menus.forEach(item => {
        item.isActive = false;
      });
    },
    activateItem(item) {
      item.isActive = true;
    }
  }
};
</script>

<style>
@font-face {
  font-family: 'fontBody';
  src: url('/src/assets/Vazir.ttf') format('opentype');
}

.body-font {
  font-family: 'fontBody', sans-serif; /* نام فونت خود را در اینجا قرار دهید */
}
</style>
