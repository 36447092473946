<template>

  <div>
    <v-list-item
        v-if="hasPermission(item)"
        :prepend-icon="item.icon"
        :class="{ 'menu-item-active': item.isActive }"
        @click="handleItemClick"
    >

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
      <!--      <v-icon v-if="item.submenu">{{ submenuVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
    </v-list-item>
    <v-list v-show="submenuVisible" dense nav class="submenu" style="background-color: #343941!important;">
      <menu-item
          v-for="(subItem, subIndex) in item.submenu"
          :key="subIndex"
          :item="subItem"
          @select-item="$emit('select-item', $event)"
          @deactivate-items="$emit('deactivate-items')"
          @activate-item="$emit('activate-item', item)"
          class="submenu-item"
      ></menu-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submenuVisible: false
    };
  },
  methods: {
    hasPermission(permissionCode) {
      return this.$root.hasPermission(permissionCode);
    },
    handleItemClick() {
      if (this.item.submenu) {
        this.toggleSubMenu();
      } else {
        this.$emit('deactivate-items');
        this.$emit('activate-item', this.item);
        this.$emit('select-item', this.item.value);
      }
    },
    toggleSubMenu() {
      this.submenuVisible = !this.submenuVisible;
    }
  }
};
</script>

<style scoped>
.menu-item-active {
  background-color: #454C57;
  color: #fff;
}

.submenu {
  background-color: #112a8c;
  padding-left: 20px;
}

.submenu-item {
  padding-left: 20px;
  color: #fff;
  //background-color: #9DA7B6;
}
</style>
