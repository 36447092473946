<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-text-field v-model="search" append-icon="mdi-magnify" placeholder="جستجو ..." solo flat dense
                                hide-details></v-text-field>
                  <v-btn-toggle v-model="activeTab" mandatory>
                    <v-btn text>
                      <span style="color: #9da7b6;">نقش‌ها</span>
                    </v-btn>
                    <v-btn text>
                      <span style="color: #9da7b6;">مجوزها</span>
                    </v-btn>
                    <v-btn text>
                      <span style="color: #9da7b6;">گروه‌های دسترسی</span>
                    </v-btn>
                    <v-btn text>
                      <span style="color: #9da7b6;">مجوزهای کاربر</span>
                    </v-btn>
                    <v-btn text>
                      <span style="color: #9da7b6;">کاربران</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-card-title>
                <v-tabs-items v-model="activeTab">
                  <v-tab-item>
                    <v-data-table
                        :headers="roleHeaders"
                        :items="roles"
                        :search="search"
                        page-text="{0}-{1} از {2}"
                        items-per-page-text="تعداد در صفحه : "
                        :items-per-page-options="probCountInpPage"
                        mobile-breakpoint="600"
                    >
                      <template v-slot:top>
                        <v-btn dense outlined small color="success" @click="openNewRoleDialog"
                               style="margin-bottom: 10px;">
                          <v-icon small>mdi-pen-plus</v-icon>
                          افزودن نقش جدید
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.code }}</td>
                          <td>{{ item.description }}</td>
                          <td>
                            <v-btn-group v-if="$q.screen.lt.md">
                              <q-btn small color="primary" @click="openEditRoleDialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                                ویرایش
                              </q-btn>
                              <q-btn @click="openDeleteDialog(item, 'role')" small color="error">
                                <v-icon small>mdi-delete</v-icon>
                                حذف
                              </q-btn>

                              <q-btn @click="showRoleDetails(item)" small color="primary">
                                <v-icon small>mdi-information-outline</v-icon>
                                جزییات
                              </q-btn>
                            </v-btn-group>
                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                                   @click="openEditRoleDialog(item)">
                              <v-icon small>mdi-pencil</v-icon>
                              ویرایش
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                                   @click="openDeleteDialog(item, 'role')">
                              <v-icon small>mdi-delete</v-icon>
                              حذف
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="mr-2"
                                   @click="showRoleDetails(item)">
                              <v-icon small>mdi-information-outline</v-icon>
                              جزییات
                            </v-btn>

                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item>
                    <v-data-table
                        :headers="permissionHeaders"
                        :items="permissions"
                        :search="search"
                        page-text="{0}-{1} از {2}"
                        items-per-page-text="تعداد در صفحه : "
                        :items-per-page-options="probCountInpPage"
                        mobile-breakpoint="600"
                    >
                      <template v-slot:top>
                        <v-btn dense outlined small color="success" @click="openNewPermissionDialog"
                               style="margin-bottom: 10px;">
                          <v-icon small>mdi-pen-plus</v-icon>
                          افزودن مجوز جدید
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.module }}</td>
                          <td>{{ item.code }}</td>
                          <td>{{ item.description }}</td>
                          <td>{{ item.action }}</td>
                          <td>{{ item.resource }}</td>
                          <td>{{ item.enabled }}</td>
                          <td>{{ item.accessLevel }}</td>
                          <td>{{ item.canRead }}</td>
                          <td>{{ item.canWrite }}</td>
                          <td>{{ item.canDelete }}</td>
                          <td>
                            <v-btn-group v-if="$q.screen.lt.md">
                              <q-btn small color="primary" @click="openEditPermissionDialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                                ویرایش
                              </q-btn>
                              <q-btn @click="openDeleteDialog(item, 'permission')" small color="error">
                                <v-icon small>mdi-delete</v-icon>
                                حذف
                              </q-btn>
                            </v-btn-group>

                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                                   @click="openEditPermissionDialog(item)">
                              <v-icon small>mdi-pencil</v-icon>
                              ویرایش
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                                   @click="openDeleteDialog(item, 'permission')">
                              <v-icon small>mdi-delete</v-icon>
                              حذف
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item>
                    <v-data-table
                        :headers="accessGroupHeaders"
                        :items="accessGroups"
                        :search="search"
                        page-text="{0}-{1} از {2}"
                        items-per-page-text="تعداد در صفحه : "
                        :items-per-page-options="probCountInpPage"
                        mobile-breakpoint="600"
                    >
                      <template v-slot:top>
                        <v-btn dense outlined small color="success" @click="openNewAccessGroupDialog"
                               style="margin-bottom: 10px;">
                          <v-icon small>mdi-pen-plus</v-icon>
                          افزودن گروه دسترسی جدید
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.description }}</td>
                          <td>
                            <span v-for="role in item.roles" :key="role.id">{{ role.name }}<br/></span>
                          </td>
                          <td>{{ item.requestLimit }}</td>
                          <td>{{ item.timeLimit }}</td>
                          <td>
                            <v-btn-group v-if="$q.screen.lt.md">
                              <q-btn small color="primary" @click="openEditAccessGroupDialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                                ویرایش
                              </q-btn>
                              <q-btn @click="openDeleteDialog(item, 'accessGroup')" small color="error">
                                <v-icon small>mdi-delete</v-icon>
                                حذف
                              </q-btn>
                            </v-btn-group>

                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                                   @click="openEditAccessGroupDialog(item)">
                              <v-icon small>mdi-pencil</v-icon>
                              ویرایش
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                                   @click="openDeleteDialog(item, 'accessGroup')">
                              <v-icon small>mdi-delete</v-icon>
                              حذف
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item>
                    <v-data-table
                        :headers="userPermissionHeaders"
                        :items="userPermissions"
                        :search="search"
                        page-text="{0}-{1} از {2}"
                        items-per-page-text="تعداد در صفحه : "
                        :items-per-page-options="probCountInpPage"
                        mobile-breakpoint="600"
                    >
                      <template v-slot:top>
                        <v-btn dense outlined small color="success" @click="openNewUserPermissionDialog"
                               style="margin-bottom: 10px;">
                          <v-icon small>mdi-pen-plus</v-icon>
                          افزودن مجوز کاربر جدید
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.user.username }}</td>
                          <!--                          <td>{{ item.permission.name }}</td>-->
                          <td>{{ item.requestLimit }}</td>
                          <td>{{ item.timeLimit }}</td>
                          <td>
                            <v-btn-group v-if="$q.screen.lt.md">
                              <q-btn small color="primary" @click="openEditUserPermissionDialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                                ویرایش
                              </q-btn>
                              <q-btn @click="openDeleteDialog(item, 'userPermission')" small color="error">
                                <v-icon small>mdi-delete</v-icon>
                                حذف
                              </q-btn>
                              <q-btn @click="showUserDetails(item)" small color="primary">
                                <v-icon small>mdi-information-outline</v-icon>
                                جزییات
                              </q-btn>
                            </v-btn-group>

                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                                   @click="openEditUserPermissionDialog(item)">
                              <v-icon small>mdi-pencil</v-icon>
                              ویرایش
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                                   @click="openDeleteDialog(item, 'userPermission')">
                              <v-icon small>mdi-delete</v-icon>
                              حذف
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="mr-2"
                                   @click="showUserDetails(item)">
                              <v-icon small>mdi-information-outline</v-icon>
                              جزییات
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                  <v-tab-item>
                    <v-data-table
                        :headers="userHeaders"
                        :items="users"
                        :search="search"
                        page-text="{0}-{1} از {2}"
                        items-per-page-text="تعداد در صفحه : "
                        :items-per-page-options="probCountInpPage"
                        mobile-breakpoint="600"
                    >
                      <template v-slot:top>
                        <v-btn dense outlined small color="success" @click="openNewUserDialog"
                               style="margin-bottom: 10px;">
                          <v-icon small>mdi-pen-plus</v-icon>
                          افزودن کاربر جدید
                        </v-btn>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.username }}</td>
                          <td>{{ item.userFullName }}</td>
                          <td>{{ item.rank }}</td>
                          <td>{{ item.profileImg }}</td>
                          <td>{{ item.type }}</td>
                          <td>
                            <span v-for="role in item.roles" :key="role.id">{{ role.name }}<br/></span>
                          </td>
                          <td>
                            <span v-for="accessGroup in item.accessGroups" :key="accessGroup.id">{{
                                accessGroup.name
                              }}<br/></span>
                          </td>
                          <td>
                            <v-btn-group v-if="$q.screen.lt.md">
                              <q-btn small color="primary" @click="openEditUserDialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                                ویرایش
                              </q-btn>
                              <q-btn @click="openDeleteDialog(item, 'user')" small color="error">
                                <v-icon small>mdi-delete</v-icon>
                                حذف
                              </q-btn>
                            </v-btn-group>

                            <v-btn v-if="!$q.screen.lt.md" small color="primary" class="ml-2"
                                   @click="openEditUserDialog(item)">
                              <v-icon small>mdi-pencil</v-icon>
                              ویرایش
                            </v-btn>
                            <v-btn v-if="!$q.screen.lt.md" small color="error" class="mr-2"
                                   @click="openDeleteDialog(item, 'user')">
                              <v-icon small>mdi-delete</v-icon>
                              حذف
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="showRoleDetailsDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">جزئیات نقش "{{ selectedRole.name }}"</span>
              </v-card-title>
              <v-card-text>
                <p><strong>کد نقش:</strong> {{ selectedRole.code }}</p>
                <p><strong>توضیحات:</strong> {{ selectedRole.description }}</p>
                <p><strong>مجوزها:</strong></p>
                <ul>
                  <li v-for="permission in selectedRole.permissions" :key="permission.id">
                    {{ permission.name }} ({{ permission.code }})
                  </li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showRoleDetailsDialog = false">بستن</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newRoleDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="newRoleDialog = false">mdi-close</v-icon>
                <span class="headline">افزودن نقش جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newRole.name" label="نام نقش"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newRole.code" label="کد نقش"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newRole.description" label="توضیحات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newRole.permissionIds" :items="permissions" item-title="name"
                                item-value="id" label="مجوز" multiple></v-select>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="createRole">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editRoleDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="editRoleDialog = false">mdi-close</v-icon>
                <span class="headline">ویرایش نقش</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedRole.name" label="نام نقش"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedRole.code" label="کد نقش"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedRole.description" label="توضیحات"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="updateRole">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newPermissionDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="newPermissionDialog = false">mdi-close</v-icon>
                <span class="headline">افزودن مجوز جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.name" label="نام مجوز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.module" label="ماژول"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.code" label="کد مجوز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.description" label="توضیحات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.action" label="عملیات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newPermission.resource" label="منبع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="newPermission.enabled" label="فعال"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newPermission.accessLevel" :items="accessLevels" label="سطح دسترسی"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="newPermission.canRead" label="خواندن"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="newPermission.canWrite" label="نوشتن"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="newPermission.canDelete" label="حذف"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="createPermission">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editPermissionDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="editPermissionDialog = false">mdi-close</v-icon>
                <span class="headline">ویرایش مجوز</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.name" label="نام مجوز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.module" label="ماژول"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.code" label="کد مجوز"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.description" label="توضیحات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.action" label="عملیات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedPermission.resource" label="منبع"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="editedPermission.enabled" label="فعال"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editedPermission.accessLevel" :items="accessLevels"
                                label="سطح دسترسی"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="editedPermission.canRead" label="خواندن"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="editedPermission.canWrite" label="نوشتن"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox v-model="editedPermission.canDelete" label="حذف"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="updatePermission">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newAccessGroupDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="newAccessGroupDialog = false">mdi-close</v-icon>
                <span class="headline">افزودن گروه دسترسی جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newAccessGroup.name" label="نام گروه دسترسی"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newAccessGroup.description" label="توضیحات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newAccessGroup.roleIds" :items="roles" item-title="name" item-value="id"
                                label="نقش‌ها" multiple></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newAccessGroup.requestLimit" label="محدودیت درخواست"
                                    type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newAccessGroup.timeLimit" label="محدودیت زمانی"
                                    type="number"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <DatePicker
                          style="font-family: 'fontBody', sans-serif"
                          v-model="newAccessGroup.thruDate"
                          display-format="jYYYY-jMM-jDD"
                          format="jYYYY-jMM-jDD"
                          placeholder="انتخاب تاریخ"
                          :auto-submit="true"
                          label="تاریخ انقضاء"
                          simple/>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="createAccessGroup">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editAccessGroupDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="editAccessGroupDialog = false">mdi-close</v-icon>
                <span class="headline">ویرایش گروه دسترسی</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccessGroup.name" label="نام گروه دسترسی"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccessGroup.description" label="توضیحات"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editedAccessGroup.roleIds" :items="roles" item-title="name" item-value="id"
                                label="نقش‌ها" multiple></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccessGroup.requestLimit" label="محدودیت درخواست"
                                    type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedAccessGroup.timeLimit" label="محدودیت زمانی"
                                    type="number"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="updateAccessGroup">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newUserPermissionDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="newUserPermissionDialog = false">mdi-close</v-icon>
                <span class="headline">افزودن مجوز کاربر جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="newUserPermission.userId" :items="users" item-title="username" item-value="id"
                                label="کاربر"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newUserPermission.permissionIds" :items="permissions" item-title="name"
                                item-value="id" label="مجوز" multiple></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-select v-model="newUserPermission.roleIds" :items="roles" item-title="name" item-value="id"
                                label="نقش‌ها" multiple></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="newUserPermission.requestLimit" label="محدودیت درخواست"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUserPermission.timeLimit" label="محدودیت زمانی"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <DatePicker
                          style="font-family: 'fontBody', sans-serif"
                          v-model="newUserPermission.thruDate"
                          display-format="jYYYY-jMM-jDD"
                          format="jYYYY-jMM-jDD"
                          placeholder="انتخاب تاریخ"
                          :auto-submit="true"
                          label="تاریخ انقضاء"
                          simple/>
                    </v-col>

                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="createUserPermission">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editUserPermissionDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="editUserPermissionDialog = false">mdi-close</v-icon>
                <span class="headline">ویرایش مجوز کاربر</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="editedUserPermission.userId" :items="users" item-title="username"
                                item-value="id" label="کاربر" disabled></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editedUserPermission.permissionId" :items="permissions" item-title="name"
                                item-value="id" label="مجوز" disabled></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUserPermission.requestLimit" label="محدودیت درخواست"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUserPermission.timeLimit" label="محدودیت زمانی"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="updateUserPermission">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="newUserDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="newUserDialog = false">mdi-close</v-icon>
                <span class="headline">افزودن کاربر جدید</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.username" label="نام کاربری"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.userFullName" label="نام کامل"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.pass" label="گذرواژه" type="password"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.rank" label="رتبه"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.profileImg" label="تصویر پروفایل"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="newUser.type" label="نوع کاربر"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newUser.roleIds" :items="roles" item-title="name" item-value="id"
                                label="نقش‌ها"
                                multiple></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="newUser.accessGroupIds" :items="accessGroups" item-title="name" item-value="id"
                                label="گروه‌های دسترسی" multiple></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="createUser">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editUserDialog" max-width="500px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="editUserDialog = false">mdi-close</v-icon>
                <span class="headline">ویرایش کاربر</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedUser.userFullName" label="نام کامل"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUser.pass" label="گذرواژه" type="password"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUser.rank" label="رتبه"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUser.profileImg" label="تصویر پروفایل"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedUser.type" label="نوع کاربر"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editedUser.roleIds" :items="roles" item-title="name" item-value="id"
                                label="نقش‌ها" multiple></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="editedUser.accessGroupIds" :items="accessGroups" item-title="name"
                                item-value="id" label="گروه‌های دسترسی" multiple></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="updateUser">ذخیره</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="showUserDetailsDialog" max-width="950"
                    style="font-family: 'fontBody', sans-serif; font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="showUserDetailsDialog = false">mdi-close</v-icon>
                <span class="headline">جزئیات کاربر "{{ selectedUser.user.userFullName }}"</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>عنوان</th>
                            <th>مقدار</th>
                          </tr>
                          <tr>
                            <td><strong>نام کاربری:</strong></td>
                            <td>{{ selectedUser.user.username }}</td>
                          </tr>
                          <tr class="alt">
                            <td><strong>نام کامل:</strong></td>
                            <td>{{ selectedUser.user.userFullName }}</td>
                          </tr>
                          <tr>
                            <td><strong>رتبه:</strong></td>
                            <td>{{ selectedUser.user.rank }}</td>
                          </tr>
                          <tr class="alt">
                            <td><strong>نوع:</strong></td>
                            <td>{{ selectedUser.user.type }}</td>
                          </tr>
                          <tr>
                            <td><strong>محدودیت درخواست:</strong></td>
                            <td>{{ selectedUser.requestLimit }}</td>
                          </tr>
                          <tr class="alt">
                            <td><strong>محدودیت زمانی:</strong></td>
                            <td>{{ selectedUser.timeLimit }}</td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span style="font-family: 'fontBody', sans-serif">گروه‌های دسترسی</span>
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>نام</th>
                            <th>توضیحات</th>
                            <th>محدودیت درخواست</th>
                            <th>محدودیت زمانی</th>
                          </tr>
                          <tr v-for="(group, index) in selectedUser.user.accessGroups" :key="group.id"
                              :class="index % 2 === 0 ? 'alt' : ''">
                            <td>{{ group.name }}</td>
                            <td>{{ group.description }}</td>
                            <td>{{ group.requestLimit }}</td>
                            <td>{{ group.timeLimit }}</td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span style="font-family: 'fontBody', sans-serif">مجوزها</span>
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>نام</th>
                            <th>کد</th>
                            <th>توضیحات</th>
                          </tr>
                          <tr v-for="(permission, index) in selectedUser.permissions" :key="permission.id"
                              :class="index % 2 === 0 ? 'alt' : ''">
                            <td>{{ permission.name }}</td>
                            <td>{{ permission.code }}</td>
                            <td>{{ permission.description }}</td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span style="font-family: 'fontBody', sans-serif">نقش‌ها</span>
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>نام</th>
                            <th>کد</th>
                            <th>توضیحات</th>
                          </tr>
                          <tr v-for="(role, index) in selectedUser.roles" :key="role.id"
                              :class="index % 2 === 0 ? 'alt' : ''">
                            <td>{{ role.name }}</td>
                            <td>{{ role.code }}</td>
                            <td>{{ role.description }}</td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-for="(role) in selectedUser.roles" :key="role.id">
                    <v-col cols="12">
                      <span style="font-family: 'fontBody', sans-serif">مجوزهای نقش "{{ role.name }}"</span>
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>نام</th>
                            <th>کد</th>
                            <th>توضیحات</th>
                          </tr>
                          <tr v-for="(permission, permissionIndex) in role.permissions" :key="permission.id"
                              :class="permissionIndex % 2 === 0 ? 'alt' : ''">
                            <td>{{ permission.name }}</td>
                            <td>{{ permission.code }}</td>
                            <td>{{ permission.description }}</td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- دیالوگ حذف -->
          <v-dialog v-model="deleteDialog" persistent max-width="400"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="headline">حذف</v-card-title>
              <v-card-text>آیا از حذف "{{ itemToDelete.name }}" اطمینان دارید؟</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="deleteDialog = false">لغو</v-btn>
                <v-btn color="error" text @click="confirmDelete">حذف</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>

    <div>
      <!-- کد دیگر کامپوننت -->
      <UiUtils ref="uiUtils"/>
    </div>

  </v-locale-provider>
</template>

<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';
import jalaliMoment from 'jalali-moment';

export default {
  name: 'RBACManagement',
  data() {
    return {
      activeTab: 0,
      deleteDialog: false,
      itemToDelete: null,
      deleteType: null,
      probCountInpPage: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: -1, title: 'نمایش همه'}
      ],
      search: '',

      // نقش‌ها
      newRoleDialog: false,
      editRoleDialog: false,
      roles: [],
      roleHeaders: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'کد', align: 'start', sortable: true, value: 'code'},
        {title: 'توضیحات', align: 'start', sortable: true, value: 'description'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      newRole: {
        name: '',
        code: '',
        description: ''
      },
      editedRole: {
        id: null,
        name: '',
        code: '',
        description: ''
      },

      // مجوزها
      newPermissionDialog: false,
      editPermissionDialog: false,
      permissions: [],
      permissionHeaders: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'ماژول', align: 'start', sortable: true, value: 'module'},
        {title: 'کد', align: 'start', sortable: true, value: 'code'},
        {title: 'توضیحات', align: 'start', sortable: true, value: 'description'},
        {title: 'عملیات', align: 'start', sortable: true, value: 'action'},
        {title: 'منبع', align: 'start', sortable: true, value: 'resource'},
        {title: 'فعال', align: 'start', sortable: true, value: 'enabled'},
        {title: 'سطح دسترسی', align: 'start', sortable: true, value: 'accessLevel'},
        {title: 'خواندن', align: 'start', sortable: true, value: 'canRead'},
        {title: 'نوشتن', align: 'start', sortable: true, value: 'canWrite'},
        {title: 'حذف', align: 'start', sortable: true, value: 'canDelete'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      newPermission: {
        name: '',
        module: '',
        code: '',
        description: '',
        action: '',
        resource: '',
        enabled: false,
        accessLevel: null,
        canRead: false,
        canWrite: false,
        canDelete: false
      },
      editedPermission: {
        id: null,
        name: '',
        module: '',
        code: '',
        description: '',
        action: '',
        resource: '',
        enabled: false,
        accessLevel: null,
        canRead: false,
        canWrite: false,
        canDelete: false
      },
      accessLevels: ['عمومی', 'خصوصی', 'محدود'],

      // گروه‌های دسترسی
      newAccessGroupDialog: false,
      editAccessGroupDialog: false,
      accessGroups: [],
      accessGroupHeaders: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام', align: 'start', sortable: true, value: 'name'},
        {title: 'توضیحات', align: 'start', sortable: true, value: 'description'},
        {title: 'نقش‌ها', align: 'start', sortable: false, value: 'roles'},
        {title: 'محدودیت درخواست', align: 'start', sortable: true, value: 'requestLimit'},
        {title: 'محدودیت زمانی', align: 'start', sortable: true, value: 'timeLimit'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      newAccessGroup: {
        name: '',
        description: '',
        roleIds: [],
        requestLimit: null,
        timeLimit: null
      },
      editedAccessGroup: {
        id: null,
        name: '',
        description: '',
        roleIds: [],
        requestLimit: null,
        timeLimit: null
      },

      // مجوزهای کاربر
      newUserPermissionDialog: false,
      editUserPermissionDialog: false,
      userPermissions: [],
      userPermissionHeaders: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'کاربر', align: 'start', sortable: true, value: 'user.username'},
        // {title: 'مجوز', align: 'start', sortable: true, value: 'permission.name'},
        {title: 'محدودیت درخواست', align: 'start', sortable: true, value: 'requestLimit'},
        {title: 'محدودیت زمانی', align: 'start', sortable: true, value: 'timeLimit'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      newUserPermission: {
        userId: null,
        permissionId: null,
        requestLimit: null,
        timeLimit: null
      },
      editedUserPermission: {
        id: null,
        userId: null,
        permissionId: null,
        requestLimit: null,
        timeLimit: null
      },

      // کاربران
      newUserDialog: false,
      editUserDialog: false,
      users: [],
      userHeaders: [
        {title: 'شناسه', align: 'start', sortable: true, value: 'id'},
        {title: 'نام کاربری', align: 'start', sortable: true, value: 'username'},
        {title: 'نام کامل', align: 'start', sortable: true, value: 'userFullName'},
        {title: 'رتبه', align: 'start', sortable: true, value: 'rank'},
        {title: 'تصویر پروفایل', align: 'start', sortable: true, value: 'profileImg'},
        {title: 'نوع', align: 'start', sortable: true, value: 'type'},
        {title: 'نقش‌ها', align: 'start', sortable: false, value: 'roles'},
        {title: 'گروه‌های دسترسی', align: 'start', sortable: false, value: 'accessGroups'},
        {title: 'مدیریت', align: 'start', sortable: false, value: 'manage'}
      ],
      newUser: {
        username: '',
        userFullName: '',
        pass: '',
        rank: '',
        profileImg: '',
        type: '',
        roleIds: [],
        accessGroupIds: []
      },
      editedUser: {
        id: null,
        userFullName: '',
        pass: '',
        rank: '',
        profileImg: '',
        type: '',
        roleIds: [],
        accessGroupIds: []
      },
      showRoleDetailsDialog: false,
      selectedRole: null,
      showUserDetailsDialog: false,
      selectedUser: null,
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      },
    }
  },
  components: {
    UiUtils
  },
  methods: {
    showUserDetails(user) {
      console.log("showUserDetails :", user)

      this.selectedUser = user;
      this.showUserDetailsDialog = true;
      console.log("showUserDetails :", this.selectedUser.user.username)
    },
    showRoleDetails(role) {
      this.selectedRole = role;
      this.showRoleDetailsDialog = true;
    },
    formatPersianDate(dateString) {
      const miladi = new Date(dateString);
      const shamsi = jalaliMoment(miladi).format('jYYYY/jM/jD HH:mm:ss');
      return shamsi;
    },
    openDeleteDialog(item, type) {
      this.itemToDelete = item;
      this.deleteType = type;
      this.deleteDialog = true;
    },
    confirmDelete() {
      switch (this.deleteType) {
        case 'role':
          this.deleteRole(this.itemToDelete);
          break;
        case 'permission':
          this.deletePermission(this.itemToDelete);
          break;
        case 'accessGroup':
          this.deleteAccessGroup(this.itemToDelete);
          break;
        case 'userPermission':
          this.deleteUserPermission(this.itemToDelete);
          break;
        case 'user':
          this.deleteUser(this.itemToDelete);
          break;
        default:
          break;
      }
      this.deleteDialog = false;
    },

    // متدهای مربوط به نقش‌ها
    openNewRoleDialog() {
      this.newRoleDialog = true;
    },
    openEditRoleDialog(role) {
      this.editedRole = Object.assign({}, role);
      this.editRoleDialog = true;
    },
    createRole() {
      const requestBody = JSON.stringify(this.newRole);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post('/authController/createRole', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            this.roles.push(response.data);
            this.newRoleDialog = false;
            this.newRole = {
              name: '',
              code: '',
              description: ''
            };
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    updateRole() {
      const requestBody = JSON.stringify(this.editedRole);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put('/authController/updateRole', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            const index = this.roles.findIndex(role => role.id === this.editedRole.id);
            this.roles.splice(index, 1, response.data);
            this.editRoleDialog = false;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    deleteRole(role) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/authController/deleteRole?roleId=${role.id}`, this.config)
          .then(() => {
            this.$refs.uiUtils.dismissIsLoading();
            const index = this.roles.findIndex(r => r.id === role.id);
            this.roles.splice(index, 1);
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },

    // متدهای مربوط به مجوزها
    openNewPermissionDialog() {
      this.newPermissionDialog = true;
    },
    openEditPermissionDialog(permission) {
      this.editedPermission = Object.assign({}, permission);
      this.editPermissionDialog = true;
    },
    createPermission() {
      const requestBody = JSON.stringify(this.newPermission);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post('/authController/createPermission', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            this.permissions.push(response.data);
            this.newPermissionDialog = false;
            this.newPermission = {
              name: '',
              module: '',
              code: '',
              description: '',
              action: '',
              resource: '',
              enabled: false,
              accessLevel: null,
              canRead: false,
              canWrite: false,
              canDelete: false
            };
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    updatePermission() {
      const requestBody = JSON.stringify(this.editedPermission);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put('/authController/updatePermission', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            const index = this.permissions.findIndex(permission => permission.id === this.editedPermission.id);
            this.permissions.splice(index, 1, response.data);
            this.editPermissionDialog = false;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    deletePermission(permission) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/authController/deletePermission?permissionId=${permission.id}`, this.config)
          .then(() => {
            this.$refs.uiUtils.dismissIsLoading();
            const index = this.permissions.findIndex(p => p.id === permission.id);
            this.permissions.splice(index, 1);
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },

    // متدهای مربوط به گروه‌های دسترسی
    openNewAccessGroupDialog() {
      this.newAccessGroupDialog = true;
    },
    openEditAccessGroupDialog(accessGroup) {
      this.editedAccessGroup = Object.assign({}, accessGroup);
      this.editAccessGroupDialog = true;
    },
    createAccessGroup() {
      const requestBody = JSON.stringify(this.newAccessGroup);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post('/authController/createAccessGroup', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            this.accessGroups.push(response.data);
            this.newAccessGroupDialog = false;
            this.newAccessGroup = {
              name: '',
              description: '',
              roleIds: [],
              requestLimit: null,
              timeLimit: null
            };
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    updateAccessGroup() {
      const requestBody = JSON.stringify(this.editedAccessGroup);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put('/authController/updateAccessGroup', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            const index = this.accessGroups.findIndex(accessGroup => accessGroup.id === this.editedAccessGroup.id);
            this.accessGroups.splice(index, 1, response.data);
            this.editAccessGroupDialog = false;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    deleteAccessGroup(accessGroup) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/authController/deleteAccessGroup?accessGroupId=${accessGroup.id}`, this.config).then(() => {
        this.$refs.uiUtils.dismissIsLoading();
        const index = this.accessGroups.findIndex(ag => ag.id === accessGroup.id);
        this.accessGroups.splice(index, 1);
      })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
// متدهای مربوط به مجوزهای کاربر
    openNewUserPermissionDialog() {
      this.newUserPermissionDialog = true;
    },
    openEditUserPermissionDialog(userPermission) {
      this.editedUserPermission = Object.assign({}, userPermission);
      this.editUserPermissionDialog = true;
    },
    createUserPermission() {
      const requestBody = JSON.stringify(this.newUserPermission);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post('/authController/createUserPermission', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            this.userPermissions.push(response.data);
            this.newUserPermissionDialog = false;
            this.newUserPermission = {
              userId: null,
              permissionId: null,
              requestLimit: null,
              timeLimit: null
            };
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    updateUserPermission() {
      const requestBody = JSON.stringify(this.editedUserPermission);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put('/authController/updateUserPermission', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            const index = this.userPermissions.findIndex(userPermission => userPermission.id === this.editedUserPermission.id);
            this.userPermissions.splice(index, 1, response.data);
            this.editUserPermissionDialog = false;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    deleteUserPermission(userPermission) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/authController/deleteUserPermission?userPermissionId=${userPermission.id}`, this.config)
          .then(() => {
            this.$refs.uiUtils.dismissIsLoading();
            const index = this.userPermissions.findIndex(up => up.id === userPermission.id);
            this.userPermissions.splice(index, 1);
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },

// متدهای مربوط به کاربران
    openNewUserDialog() {
      this.newUserDialog = true;
    },
    openEditUserDialog(user) {
      this.editedUser = Object.assign({}, user);
      this.editUserDialog = true;
    },
    createUser() {
      const requestBody = JSON.stringify(this.newUser);
      this.$refs.uiUtils.showIsLoading();
      ApiService.post('/authController/createUser', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            this.users.push(response.data);
            this.newUserDialog = false;
            this.newUser = {
              username: '',
              userFullName: '',
              pass: '',
              rank: '',
              profileImg: '',
              type: '',
              roleIds: [],
              accessGroupIds: []
            };
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    updateUser() {
      const requestBody = JSON.stringify(this.editedUser);
      this.$refs.uiUtils.showIsLoading();
      ApiService.put('/authController/updateUser', requestBody, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSuccessDialog();
            const index = this.users.findIndex(user => user.id === this.editedUser.id);
            this.users.splice(index, 1, response.data);
            this.editUserDialog = false;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    deleteUser(user) {
      this.$refs.uiUtils.showIsLoading();
      ApiService.delete(`/authController/deleteUser?userId=${user.id}`, this.config)
          .then(() => {
            this.$refs.uiUtils.dismissIsLoading();
            const index = this.users.findIndex(u => u.id === user.id);
            this.users.splice(index, 1);
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },

// متدهای دریافت داده‌ها از سرور
    getRolesFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get('/authController/getAllRoles', this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.roles = response.data;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    getPermissionsFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get('/authController/getAllPermissions', this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.permissions = response.data;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    getAccessGroupsFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get('/authController/getAllAccessGroups', this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.accessGroups = response.data;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    getUserPermissionsFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get('/authController/getAllUserPermissions', this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.userPermissions = response.data;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    getUsersFromServer() {
      this.$refs.uiUtils.showIsLoading();
      ApiService.get('/authController/getAllUsers', this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.users = response.data;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    }
  },
  mounted() {
    this.getRolesFromServer();
    this.getPermissionsFromServer();
    this.getAccessGroupsFromServer();
    this.getUserPermissionsFromServer();
    this.getUsersFromServer();
  }
}
</script>

<style scoped>
div :deep(.persianDatePicker),
div :deep([disabled]),
div :deep(.vpd-day-text),
div :deep(.vpd-day-effect) {
  font-family: 'fontBody', sans-serif !important;
  opacity: 1 !important;
  cursor: pointer !important;
}

div :deep([disabled]:hover) {
  opacity: 0.8 !important;
}

.custom-date {
  border: 0.5px solid gray;
  border-radius: 3px;
}

.custom-date:hover {
  border: 1px solid black;
}

div :deep(.persianDatePicker) > span {
  height: 37px;
  overflow: hidden;
}
</style>
