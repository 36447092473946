class ErrorService {
    handleApiError(response) {
        let errorMessage = 'خطای ناشناخته رخ داده است.';
      if (response.request) {
            errorMessage = 'خطای شبکه. لطفا اتصال اینترنت خود را بررسی کنید.';
        }else if (response){
            errorMessage = response;
        }
        throw new Error(errorMessage);
    }

    handleNetworkError(error) {
        if (error.response) {
            if (error.response.data && error.response.data.message) {
                throw this.handleApiError(error.response.data.message);
            } else {
                throw this.handleApiError(`خطای ${error.response.status}: ${error.response.statusText}`);
            }
        } else if (error.request) {
            throw new Error('خطای شبکه. لطفا اتصال اینترنت خود را بررسی کنید.');
        } else {
            throw new Error('خطای ناشناخته رخ داده است.');
        }
    }
}

export default new ErrorService();
