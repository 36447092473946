<template>
  <v-locale-provider rtl>
    <v-app>
      <v-main>
        <v-container fluid style="background-color: #343941;">
          <v-row style="background-color: #343941">
            <v-col cols="12">
              <v-card color="teal lighten-4" outlined>
                <v-card-title class="justify-space-between" style="background-color: #343941">
                  <v-select
                      v-model="productIds"
                      :items="products"
                      item-title="name"
                      item-value="id"
                      label="محصولات"
                      @update:model-value="calculateSimultaneousProduction"

                      multiple
                  ></v-select>
                </v-card-title>

                <v-data-table :headers="headers" :items="productionQuantities" :search="search"
                              page-text="{0}-{1} از {2}"
                              items-per-page-text="تعداد در صفحه : " :items-per-page-options="probCountInpPage"
                              mobile-breakpoint="600">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.productMdl.title }}</td>
                      <td>{{ item.productMdl.categoryInfo.name }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>
                        <v-chip v-if="canProduce(item)" color="success">قابل تولید</v-chip>
                        <v-chip v-else color="error">غیرقابل تولید</v-chip>
                      </td>
                      <td>
                        <v-btn small color="primary" class="ml-2" @click="showDetails(item)">
                          <v-icon small>mdi-eye</v-icon>
                          مشاهده جزئیات
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- Dialog for showing details -->


          <v-dialog v-model="detailsDialog" max-width="800px"
                    style="font-family: 'fontBody', sans-serif;font-size: 18px">
            <v-card>
              <v-card-title class="justify-space-between">
                <v-icon @click="detailsDialog = false">mdi-close</v-icon>
                <span class="headline">جزئیات تولید {{ selectedItem.productMdl.title }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-img :src="getImgUrl(selectedItem.productMdl.img)" height="250px" contain></v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>عنوان</th>
                            <th>مقدار</th>
                          </tr>
                          <tr>
                            <td><strong>نام محصول:</strong></td>
                            <td>{{ selectedItem.productMdl.title }}</td>
                          </tr>
                          <tr class="alt">
                            <td><strong>دسته بندی:</strong></td>
                            <td>{{ selectedItem.productMdl.categoryInfo.name }}</td>
                          </tr>
                          <tr>
                            <td><strong>تعداد تولید:</strong></td>
                            <td>{{ selectedItem.quantity }}</td>
                          </tr>
                          <tr class="alt">
                            <td><strong>قیمت واحد:</strong></td>
                            <td>{{ selectedItem.productMdl.price }}</td>
                          </tr>
                          <tr>
                            <td><strong>قابلیت تولید:</strong></td>
                            <td>
                      <span
                          :style="{
                            backgroundColor: canProduce(selectedItem) ? 'green' : 'red',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px'
                          }"
                      >
                        {{ canProduce(selectedItem) ? 'قابل تولید' : 'غیرقابل تولید' }}
                      </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span style="font-family: 'fontBody', sans-serif">لیست مواد اولیه مورد نیاز</span>
                      <div class="order-info">
                        <table>
                          <tr>
                            <th>نام</th>
                            <th>مقدار مورد نیاز</th>
                            <th>موجودی انبار</th>
                            <th>واحد</th>
                            <th>وضعیت</th>
                          </tr>
                          <tr v-for="(item, index) in selectedItem.formulaItems" :key="item.material.id"
                              :class="index % 2 === 0 ? 'alt' : ''">
                            <td>{{ item.material.name }}</td>
                            <td>{{ item.requiredQuantity }}</td>
                            <td>{{ item.currentInventory }}</td>
                            <td>{{ item.material.unitOfMeasure.name }}</td>
                            <td>
                      <span
                          :style="{
                            backgroundColor: item.currentInventory >= item.requiredQuantity ? 'green' : 'red',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px'
                          }"
                      >
                        {{ item.currentInventory >= item.requiredQuantity ? 'موجود' : 'ناکافی' }}
                      </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>


        </v-container>
      </v-main>
    </v-app>
    <div>
      <UiUtils ref="uiUtils"/>
    </div>
  </v-locale-provider>
</template>
<script>
import UiUtils from '../components/util/UiUtils.vue';
import ApiService from '../services/ApiService.js';

export default {
  name: 'ProductionQuantities',
  data() {
    return {
      products: [],
      productIds: [],
      probCountInpPage: [{value: 10, title: '10'}, {value: 25, title: '25'}, {value: 50, title: '50'}, {
        value: -1,
        title: 'نمایش همه'
      }],
      search: '',
      detailsDialog: false,
      selectedItem: null,
      headers: [
        {title: 'محصول', align: 'start', sortable: true, value: 'productMdl.title'},
        {title: 'دسته بندی', align: 'start', sortable: true, value: 'productMdl.categoryInfo.name'},
        {title: 'تعداد تولید', align: 'start', sortable: true, value: 'quantity'},
        {title: 'قابلیت تولید', align: 'start', sortable: true, value: 'producible'},
        {title: 'جزئیات', align: 'start', sortable: false, value: 'details'}
      ],
      productionQuantities: [],
      config: {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    UiUtils
  },
  methods: {
    calculateSimultaneousProduction() {
      console.log("calculateSimultaneousProduction")
      const productIds = this.productIds.join(',');
      const url = `formula/calculateSimultaneousProduction?productIds=${productIds}`;
      this.$refs.uiUtils.showIsLoading();
      ApiService.get(url, this.config)
          .then(response => {
            this.$refs.uiUtils.dismissIsLoading();
            this.productionQuantities = response;
          })
          .catch(error => {
            this.$refs.uiUtils.dismissIsLoading();
            this.$refs.uiUtils.showSnackErrorMessage(error.message);
          });
    },
    getImgUrl(img) {
      return ApiService.getBaseUrl() + "/" + img;
    },
    canProduce(item) {
      return item.quantity > 0;
    },
    showDetails(item) {
      this.selectedItem = item;
      this.detailsDialog = true;
    },

    getProductsFromServer() {
      this.$refs.uiUtils.showIsLoading()
      ApiService.get("/product/getProductsSelect", this.config).then(response => {
        this.$refs.uiUtils.dismissIsLoading()
        this.products = response;
      }).catch(error => {
        this.$refs.uiUtils.dismissIsLoading()
        console.log(error)
      })
    },
  },
  mounted() {
    this.getProductsFromServer();
  }
}
</script>
